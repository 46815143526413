<template>
  <div class="row">
    <div class="col-12">
      <div class="card">

        <!-- card header -->
        <div class="card-header">
          <h4>Preencha os dados abaixo</h4>

          <a @click="newData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
        </div>
        <!-- end card header -->

        <!-- card body -->
        <div class="card-body">

          <!-- form -->
          <h6 class="heading-small text-muted mb-4">Dados Básicos</h6>
          <div class="row">

            <!-- name -->
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label">Nome</label>
                <input type="text" id="name" v-bind:class="{'is-invalid': errors.name}" class="form-control" placeholder="Nome" v-model="name">
                <div class="invalid-feedback" v-if="errors.name">
                    <p>{{ errors.name[0] }}</p>
                </div>
              </div><!-- form group -->
            </div><!-- col-lg-12 -->
            <!-- end name -->

          </div><!-- row -->

          <!-- divider -->
          <hr class="my-4" />
          <!-- Address -->
          <h6 class="heading-small text-muted mb-4">Acessos Padrões</h6>
          <div class="row">
            <div class="col-md-12">

              <!-- item title -->
              <div class="row">
                <div class="col-md-8">

                </div>
                <div class="col-md-1">
                  <label>Listar</label>
                </div>
                <div class="col-md-1">
                  <label>Criar</label>
                </div>
                <div class="col-md-1">
                  <label>Editar</label>
                </div>
                <div class="col-md-1">
                  <label>Excluir</label>
                </div>
              </div>
              <!-- end item title -->

              <div class="row">
                <div class="col-md-8">
                  <label>Usuários</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="usuarios-list" v-model="permission.users.list" v-on:change="changeToggle('users-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="usuarios-create" v-model="permission.users.create" v-on:change="changeToggle('users-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="usuarios-edit" v-model="permission.users.edit" v-on:change="changeToggle('users-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="usuarios-remove" v-model="permission.users.remove" v-on:change="changeToggle('users-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Grupos de Acesso</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-acesso-list" v-model="permission.permissions.list" v-on:change="changeToggle('permissions-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-acesso-create" v-model="permission.permissions.create" v-on:change="changeToggle('permissions-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-acesso-edit" v-model="permission.permissions.edit" v-on:change="changeToggle('permissions-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-acesso-remove" v-model="permission.permissions.remove" v-on:change="changeToggle('permissions-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

            </div>
          </div>

          <!-- divider -->
          <hr class="my-4" />
          <!-- Address -->
          <h6 class="heading-small text-muted mb-4">Acessos Doadores</h6>
          <div class="row">
            <div class="col-md-12">

              <div class="row">
                <div class="col-md-8">
                  <label>Cores de Cabelo</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-cabelo-list" v-model="permission.hair_color.list" v-on:change="changeToggle('haircolors-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-cabelo-create" v-model="permission.hair_color.create" v-on:change="changeToggle('haircolors-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-cabelo-edit" v-model="permission.hair_color.edit" v-on:change="changeToggle('haircolors-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-cabelo-remove" v-model="permission.hair_color.remove" v-on:change="changeToggle('haircolors-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Cor de Olhos</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-olho-list" v-model="permission.eye_color.list" v-on:change="changeToggle('eyecolors-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-olho-create" v-model="permission.eye_color.create" v-on:change="changeToggle('eyecolors-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-olho-edit" v-model="permission.eye_color.edit" v-on:change="changeToggle('eyecolors-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-olho-remove" v-model="permission.eye_color.remove" v-on:change="changeToggle('eyecolors-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Cores de Pele</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-pele-list" v-model="permission.skin_color.list" v-on:change="changeToggle('skincolors-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-pele-create" v-model="permission.skin_color.create" v-on:change="changeToggle('skincolors-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-pele-edit" v-model="permission.skin_color.edit" v-on:change="changeToggle('skincolors-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-pele-remove" v-model="permission.skin_color.remove" v-on:change="changeToggle('skincolors-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Estrutura Óssea</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="estrutura-ossea-list" v-model="permission.bone_structure.list" v-on:change="changeToggle('bonestructures-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="estrutura-ossea-create" v-model="permission.bone_structure.create" v-on:change="changeToggle('bonestructures-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="estrutura-ossea-edit" v-model="permission.bone_structure.edit" v-on:change="changeToggle('bonestructures-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="estrutura-ossea-remove" v-model="permission.bone_structure.remove" v-on:change="changeToggle('bonestructures-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Etnias</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="etnias-list" v-model="permission.ethnic.list" v-on:change="changeToggle('ethnics-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="etnias-create" v-model="permission.ethnic.create" v-on:change="changeToggle('ethnics-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="etnias-edit" v-model="permission.ethnic.edit" v-on:change="changeToggle('ethnics-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="etnias-remove" v-model="permission.ethnic.remove" v-on:change="changeToggle('ethnics-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Grupo Sanguíneo</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-sanguineo-list" v-model="permission.blood_group.list" v-on:change="changeToggle('bloodgroups-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-sanguineo-create" v-model="permission.blood_group.create" v-on:change="changeToggle('bloodgroups-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-sanguineo-edit" v-model="permission.blood_group.edit" v-on:change="changeToggle('bloodgroups-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-sanguineo-remove" v-model="permission.blood_group.remove" v-on:change="changeToggle('bloodgroups-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Linguagens</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="linguagens-list" v-model="permission.language.list" v-on:change="changeToggle('languages-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="linguagens-create" v-model="permission.language.create" v-on:change="changeToggle('languages-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="linguagens-edit" v-model="permission.language.edit" v-on:change="changeToggle('languages-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="linguagens-remove" v-model="permission.language.remove" v-on:change="changeToggle('languages-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Procedimentos</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="procedimentos-list" v-model="permission.procedures.list" v-on:change="changeToggle('procedures-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="procedimentos-create" v-model="permission.procedures.create" v-on:change="changeToggle('procedures-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="procedimentos-edit" v-model="permission.procedures.edit" v-on:change="changeToggle('procedures-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="procedimentos-remove" v-model="permission.procedures.remove" v-on:change="changeToggle('procedures-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Raças</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="racas-list" v-model="permission.races.list" v-on:change="changeToggle('races-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="racas-create" v-model="permission.races.create" v-on:change="changeToggle('races-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="racas-edit" v-model="permission.races.edit" v-on:change="changeToggle('races-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="racas-remove" v-model="permission.races.remove" v-on:change="changeToggle('races-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Religiões</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="religioes-list" v-model="permission.religion.list" v-on:change="changeToggle('religions-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="religioes-create" v-model="permission.religion.create" v-on:change="changeToggle('religions-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="religioes-edit" v-model="permission.religion.edit" v-on:change="changeToggle('religions-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="religioes-remove" v-model="permission.religion.remove" v-on:change="changeToggle('religions-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Signos</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="signos-list" v-model="permission.zodiac_signs.list" v-on:change="changeToggle('zodiacsigns-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="signos-create" v-model="permission.zodiac_signs.create" v-on:change="changeToggle('zodiacsigns-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="signos-edit" v-model="permission.zodiac_signs.edit" v-on:change="changeToggle('zodiacsigns-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="signos-remove" v-model="permission.zodiac_signs.remove" v-on:change="changeToggle('zodiacsigns-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Texturas de Cabelo</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="textura-cabelo-list" v-model="permission.hair_texture.list" v-on:change="changeToggle('hairtextures-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="textura-cabelo-create" v-model="permission.hair_texture.create" v-on:change="changeToggle('hairtextures-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="textura-cabelo-edit" v-model="permission.hair_texture.edit" v-on:change="changeToggle('hairtextures-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="textura-cabelo-remove" v-model="permission.hair_texture.remove" v-on:change="changeToggle('hairtextures-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

            </div><!-- col-md-12 -->
          </div><!-- row -->

          <!-- divider -->
          <hr class="my-4" />
          <!-- Address -->
          <h6 class="heading-small text-muted mb-4">Acessos Especiais</h6>

          <div class="row mt-4">
            <div class="col-md-12">

              <!-- item title -->
              <div class="row">
                <div class="col-md-6">

                </div>
                <div class="col-md-1">
                  <label>Listar</label>
                </div>
                <div class="col-md-1">
                  <label>Criar</label>
                </div>
                <div class="col-md-1">
                  <label>Editar</label>
                </div>
                <div class="col-md-1">
                  <label>Excluir</label>
                </div>
                <div class="col-md-1">
                  <label>Ver Alterações</label>
                </div>
                <div class="col-md-1">
                  <label>Aprovar Alterações</label>
                </div>
              </div>
              <!-- end item title -->

              <div class="row">
                <div class="col-md-6">
                  <label>Doadores</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-list" v-model="permission.donor.list" v-on:change="changeToggle('donors-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-create" v-model="permission.donor.create" v-on:change="changeToggle('donors-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-edit" v-model="permission.donor.edit" v-on:change="changeToggle('donors-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-remove" v-model="permission.donor.remove" v-on:change="changeToggle('donors-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-updates-view" v-model="permission.donor.updates_view" v-on:change="changeToggle('donors-updates-view')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-updates-approve" v-model="permission.donor.updates_approve" v-on:change="changeToggle('donors-updates-approve')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

            </div><!-- col-md-12 -->
          </div><!-- row -->

        </div><!-- card body -->
      </div><!-- card -->
    </div><!-- col-12 -->

    <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
  </div><!-- row -->
</template>

<script>
  export default {
    props: ['info','role'],
    data() {
      return {
        name: JSON.parse(this.role).name,
        permission: {
          bone_structure: {
            list: this.getData('bonestructures-list'),
            create: this.getData('bonestructures-create'),
            edit: this.getData('bonestructures-edit'),
            remove: this.getData('bonestructures-delete')
          },
          blood_group: {
            list: this.getData('bloodgroups-list'),
            create: this.getData('bloodgroups-create'),
            edit: this.getData('bloodgroups-edit'),
            remove: this.getData('bloodgroups-delete')
          },
          clinic: {
            list: this.getData('clinics-list'),
            create: this.getData('clinics-create'),
            edit: this.getData('clinics-edit'),
            remove: this.getData('clinics-delete')
          },
          contact: {
            list: this.getData('contacts-list'),
            create: this.getData('contacts-create'),
            edit: this.getData('contacts-edit'),
            remove: this.getData('contacts-delete')
          },
          donor: {
            list: this.getData('donors-list'),
            create: this.getData('donors-create'),
            edit: this.getData('donors-edit'),
            remove: this.getData('donors-delete'),
            updates_view: this.getData('donors-updates-view'),
            updates_approve: this.getData('donors-updates-approve')
          },
          doubts: {
            list: this.getData('doubts-list'),
            create: this.getData('doubts-create'),
            edit: this.getData('doubts-edit'),
            remove: this.getData('doubts-delete')
          },
          ethnic: {
            list: this.getData('ethnics-list'),
            create: this.getData('ethnics-create'),
            edit: this.getData('ethnics-edit'),
            remove: this.getData('ethnics-delete')
          },
          hair_color: {
            list: this.getData('haircolors-list'),
            create: this.getData('haircolors-create'),
            edit: this.getData('haircolors-edit'),
            remove: this.getData('haircolors-delete')
          },
          hair_texture: {
            list: this.getData('hairtextures-list'),
            create: this.getData('hairtextures-create'),
            edit: this.getData('hairtextures-edit'),
            remove: this.getData('hairtextures-delete')
          },
          eye_color: {
            list: this.getData('eyecolors-list'),
            create: this.getData('eyecolors-create'),
            edit: this.getData('eyecolors-edit'),
            remove: this.getData('eyecolors-delete')
          },
          language: {
            list: this.getData('languages-list'),
            create: this.getData('languages-create'),
            edit: this.getData('languages-edit'),
            remove: this.getData('languages-delete')
          },
          media: {
            list: this.getData('medias-list'),
            create: this.getData('medias-create'),
            edit: this.getData('medias-edit'),
            remove: this.getData('medias-delete')
          },
          news: {
            list: this.getData('news-list'),
            create: this.getData('news-create'),
            edit: this.getData('news-edit'),
            remove: this.getData('news-delete')
          },
          newsletter: {
            list: this.getData('newsletter-list'),
            create: this.getData('newsletter-create'),
            edit: this.getData('newsletter-edit'),
            remove: this.getData('newsletter-delete'),
            download_all: this.getData('newsletter-download-all'),
            download_pending: this.getData('newsletter-download-pending')
          },
          permissions: {
            list: this.getData('permissions-list'),
            create: this.getData('permissions-create'),
            edit: this.getData('permissions-edit'),
            remove: this.getData('permissions-delete')
          },
          procedures: {
            list: this.getData('procedures-list'),
            create: this.getData('procedures-create'),
            edit: this.getData('procedures-edit'),
            remove: this.getData('procedures-delete')
          },
          races: {
            list: this.getData('races-list'),
            create: this.getData('races-create'),
            edit: this.getData('races-edit'),
            remove: this.getData('races-delete')
          },
          religion: {
            list: this.getData('religions-list'),
            create: this.getData('religions-create'),
            edit: this.getData('religions-edit'),
            remove: this.getData('religions-delete')
          },
          schedule: {
            list: this.getData('schedules-list'),
            create: this.getData('schedules-create'),
            download_pending: this.getData('schedules-download-pending'),
            download_all: this.getData('schedules-download-all')
          },
          skin_color: {
            list: this.getData('skincolors-list'),
            create: this.getData('skincolors-create'),
            edit: this.getData('skincolors-edit'),
            remove: this.getData('skincolors-delete')
          },
          users: {
            list: this.getData('users-list'),
            create: this.getData('users-create'),
            edit: this.getData('users-edit'),
            remove: this.getData('users-delete')
          },
          zodiac_signs: {
            list: this.getData('zodiacsigns-list'),
            create: this.getData('zodiacsigns-create'),
            edit: this.getData('zodiacsigns-edit'),
            remove: this.getData('zodiacsigns-delete')
          }
        },
        permissions: JSON.parse(this.info),
        permissionsRemoved: [],
        errors: [],
        message: '',
        loading: false,
        fullPage: true
      }
    },
    mounted() {
    },
    methods: {
      getData(key) {
        //return JSON.parse(this.info)[key];
        var temp = JSON.parse(this.info);

        var check = temp.includes(key);

        //validate check
        if (check){

          return true;

        } else {

          return false;
        }
        //end if validate check

      },
      changeToggle(toggle) {
        const index = this.permissions.indexOf(toggle)

        if (index == -1) {
          this.permissions.push(toggle)

          const indexRemoved = this.permissionsRemoved.indexOf(toggle)

          if (indexRemoved != -1) {
            this.permissionsRemoved.splice(indexRemoved, 1)
          }
        } else {
          this.permissions.splice(index, 1)

          const indexRemoved = this.permissionsRemoved.indexOf(toggle)

          if (indexRemoved == -1) {
            this.permissionsRemoved.push(toggle)
          }
        }
      },
      newData() {
        if (this.name == ""){


        } else {

          let _this = this;
          _this.errors = [];
          _this.message = '';
          _this.loading = true;
          let dataUser = {
            'name': _this.name,
            'permission': _this.permission,
            'permissions': _this.permissions,
            'removed': _this.permissionsRemoved,
            '_method': 'PATCH'
          };
          axios.post('/admin/permissions/'+JSON.parse(this.role).id, dataUser).then((res) => {

            _this.loading = false;

            //show confirm alert
            _this.$swal({
              title: 'Sucesso',
              text: 'Grupo de Acesso cadastrado com sucesso!',
              icon: 'success'
            });
          }).catch((err) => {

            _this.loading = false;

            //show error alert
            _this.$swal({
              title: 'Erro',
              text: 'Falha ao editar grupo de acesso. Tente novamente!',
              icon: 'error'
            });
          });

        }
      }
    }
  }
</script>
