<template>
  <div class="row">
    <div class="col">

      <!-- table -->
      <div class="table-responsive pb-4">
        <table class="table table-striped bg-white" id="datatable">
          <thead class="thead-light">
            <tr>
              <th>Name</th>
              <th>E-mail</th>
              <th class="text-right">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user, index in users" :key="index">
              <td>{{user.profile.name}}</td>
              <td>{{user.email}}</td>
              <td class="text-right">
                <div class="dropdown">
                  <a class="btn btn-sm btn-icon-only" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="fas fa-cogs"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right text-right" v-if="canEdit || canDelete">
                    <a class="dropdown-item has-icon" @click="editData(user.id)" v-if="canEdit"><i class="far fa-edit"></i>Editar</a>
                    <a class="dropdown-item text-danger has-icon" @click="deleteData(user.id, index)" v-if="canDelete"><i class="far fa-trash-alt"></i>Excluir</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="!users.length && !loading" class="text-center p-3 text-muted">
            <h5>Nenhum usuário(a) encontrado(a)!</h5>
        </div>
      </div>
      <!-- end table -->

      <div class="text-center p-4 text-muted" v-if="loading">
        <h5>Carregando...</h5>
        <p>Aguarde até que os dados sejam carregados...</p>
      </div>

    </div><!-- col -->
  </div><!-- row -->
</template>

<script>
  export default {
    props: [
      'canEdit',
      'canDelete',
    ],
    data() {
      return {
        users: [],
        loading: false
      }
    },
    mounted() {
      this.loadUsers();
    },
    methods: {
      loadUsers() {
        let _this = this;
        _this.loading = true;
        axios.get('/admin/users').then((res) => {
          _this.users = _this.users.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });
      },
      deleteData(ID, index) {
        let _this = this;

        //show alert confirmation
        _this.$swal({
          title: "Excluir usuário(a)",
          text: "Tem certeza que deseja excluir esse(a) usuário(a)?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: 'Excluir',
          cancelButtonText: 'Cancelar'
        }).then((alertConfirm) => {

          //validate isConfirmed
          if (alertConfirm.isConfirmed) {

            //remove user
            axios.delete('/admin/users/'+ID).then((res) => {

              _this.users.splice(index, 1);
              _this.total = _this.total - 1;
              //_this.loadUsers();

              //show confirm alert
              _this.$swal({
                title: 'Sucesso',
                text: 'Usuário(a) excluído com sucesso!',
                icon: 'success'
              });

            }).catch(error => {

              //show error alert
              _this.$swal({
                title: 'Erro',
                text: 'Falha ao excluir o(a) usuário(a). Tente novamente!',
                icon: 'error'
              });

            });

          } else {

            //only close

          }//end validate isConfirmed

        });
        //end alert confirmation

      },
      editData(ID){
        location.href="/admin/users/"+ID+"/edit";
      }
    }
  }
</script>
