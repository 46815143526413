<template>
  <div class="dropdown-list-content dropdown-list-icons">
    <button class="dropdown-item dropdown-item-unread" v-for="item in notifications" v-bind:key="item.id" @click="open(item)">
      <div class="dropdown-item-icon bg-primary text-white">
        <i class="fas fa-code"></i>
      </div>
      <div class="dropdown-item-desc">
        {{item.action}}
        <div class="time text-primary">{{item.created_at | moment('DD/MM/YYYY HH:mm:ss')}}</div>
      </div>
    </button>
    <div class="text-center py-2">
      <a href="/admin/log/notifications">Ver todas as notificações</a>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        notifications: [],
        loading: false
      }
    },
    mounted() {
      this.loadItems();
    },
    methods: {
      loadItems() {
        let _this = this;
        _this.loading = true;
        axios.get('/admin/log/notifications/json', {
          params: {
            take: 4,
            viewed: 0,
            groupBy: 'action_id',
          },
        }).then((res) => {
          _this.notifications = _this.notifications.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });
      },
      
      open(notification) {
        axios.put(`/admin/log/notifications/${notification.id}/viewed`).finally(function() {
          if (notification.action_type === 'DONOR - PENDING') {
            window.location.href = `/admin/donors/${notification.action_id}/updates/list`;
          }
        });
      },
    }
  }
</script>
