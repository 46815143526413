<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        
        <!-- card header -->
        <div class="card-header">
          <h4>Preencha os dados abaixo</h4>

          <a @click="newData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
        </div>
        <!-- end card header -->

        <!-- card body -->
        <div class="card-body">

          <!-- form -->
          <h6 class="heading-small text-muted mb-4">Dados Básicos</h6>
          <div class="row">

            <!-- name -->
            <div class="col-12">
              <div class="form-group">
                <label class="form-control-label">Nome</label>
                <input type="text" id="name" v-bind:class="{'is-invalid': errors.name}" class="form-control" placeholder="Nome" v-model="name">
                <div class="invalid-feedback" v-if="errors.name">
                    <p>{{ errors.name[0] }}</p>
                </div>
              </div><!-- form group -->
            </div><!-- col-lg-12 -->
            <!-- end name -->

          </div><!-- row -->

          <!-- divider -->
          <hr class="my-4" />
          <!-- Address -->
          <h6 class="heading-small text-muted mb-4">Acessos Padrões</h6>
          <div class="row">
            <div class="col-md-12">

              <!-- item title -->
              <div class="row">
                <div class="col-md-8">
                  
                </div>
                <div class="col-md-1">
                  <label>Listar</label>
                </div>
                <div class="col-md-1">
                  <label>Criar</label>
                </div>
                <div class="col-md-1">
                  <label>Editar</label>
                </div>
                <div class="col-md-1">
                  <label>Excluir</label>
                </div>
              </div>
              <!-- end item title -->

              <div class="row">
                <div class="col-md-8">
                  <label>Clínicas</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="clinicas-list" v-model="permission.clinic.list" v-on:change="changeToggle('clinics-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="clinicas-create" v-model="permission.clinic.create" v-on:change="changeToggle('clinics-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="clinicas-edit" v-model="permission.clinic.edit" v-on:change="changeToggle('clinics-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="clinicas-remove" v-model="permission.clinic.remove" v-on:change="changeToggle('clinics-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Contatos</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="contatos-list" v-model="permission.contact.list" v-on:change="changeToggle('contacts-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="contatos-create" v-model="permission.contact.create" v-on:change="changeToggle('contacts-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="contatos-edit" v-model="permission.contact.edit" v-on:change="changeToggle('contacts-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="contatos-remove" v-model="permission.contact.remove" v-on:change="changeToggle('contacts-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Dúvidas</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="duvidas-list" v-model="permission.doubts.list" v-on:change="changeToggle('doubts-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="duvidas-create" v-model="permission.doubts.create" v-on:change="changeToggle('doubts-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="duvidas-edit" v-model="permission.doubts.edit" v-on:change="changeToggle('doubts-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="duvidas-remove" v-model="permission.doubts.remove" v-on:change="changeToggle('doubts-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Mídias</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="midias-list" v-model="permission.media.list" v-on:change="changeToggle('medias-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="midias-create" v-model="permission.media.create" v-on:change="changeToggle('medias-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="midias-edit" v-model="permission.media.edit" v-on:change="changeToggle('medias-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="midias-remove" v-model="permission.media.remove" v-on:change="changeToggle('medias-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Notícias (Blog)</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="noticias-list" v-model="permission.news.list" v-on:change="changeToggle('news-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="noticias-create" v-model="permission.news.create" v-on:change="changeToggle('news-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="noticias-edit" v-model="permission.news.edit" v-on:change="changeToggle('news-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="noticias-remove" v-model="permission.news.remove" v-on:change="changeToggle('news-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Usuários</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="usuarios-list" v-model="permission.users.list" v-on:change="changeToggle('users-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="usuarios-create" v-model="permission.users.create" v-on:change="changeToggle('users-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="usuarios-edit" v-model="permission.users.edit" v-on:change="changeToggle('users-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="usuarios-remove" v-model="permission.users.remove" v-on:change="changeToggle('users-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Grupos de Acesso</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-acesso-list" v-model="permission.permissions.list" v-on:change="changeToggle('permissions-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-acesso-create" v-model="permission.permissions.create" v-on:change="changeToggle('permissions-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-acesso-edit" v-model="permission.permissions.edit" v-on:change="changeToggle('permissions-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-acesso-remove" v-model="permission.permissions.remove" v-on:change="changeToggle('permissions-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

            </div>
          </div>      

          <!-- divider -->
          <hr class="my-4" />
          <!-- Address -->
          <h6 class="heading-small text-muted mb-4">Acessos Doadores</h6>
          <div class="row">
            <div class="col-md-12">

              <div class="row">
                <div class="col-md-8">
                  <label>Cores de Cabelo</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-cabelo-list" v-model="permission.hair_color.list" v-on:change="changeToggle('haircolors-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-cabelo-create" v-model="permission.hair_color.create" v-on:change="changeToggle('haircolors-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-cabelo-edit" v-model="permission.hair_color.edit" v-on:change="changeToggle('haircolors-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-cabelo-remove" v-model="permission.hair_color.remove" v-on:change="changeToggle('haircolors-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Cor de Olhos</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-olho-list" v-model="permission.eye_color.list" v-on:change="changeToggle('eyecolors-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-olho-create" v-model="permission.eye_color.create" v-on:change="changeToggle('eyecolors-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-olho-edit" v-model="permission.eye_color.edit" v-on:change="changeToggle('eyecolors-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-olho-remove" v-model="permission.eye_color.remove" v-on:change="changeToggle('eyecolors-remove')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Cores de Pele</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-pele-list" v-model="permission.skin_color.list" v-on:change="changeToggle('skincolors-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-pele-create" v-model="permission.skin_color.create" v-on:change="changeToggle('skincolors-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-pele-edit" v-model="permission.skin_color.edit" v-on:change="changeToggle('skincolors-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="cor-pele-remove" v-model="permission.skin_color.remove" v-on:change="changeToggle('skincolors-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Estrutura Óssea</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="estrutura-ossea-list" v-model="permission.bone_structure.list" v-on:change="changeToggle('bonestructures-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="estrutura-ossea-create" v-model="permission.bone_structure.create" v-on:change="changeToggle('bonestructures-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="estrutura-ossea-edit" v-model="permission.bone_structure.edit" v-on:change="changeToggle('bonestructures-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="estrutura-ossea-remove" v-model="permission.bone_structure.remove" v-on:change="changeToggle('bonestructures-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Etnias</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="etnias-list" v-model="permission.ethnic.list" v-on:change="changeToggle('ethnics-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="etnias-create" v-model="permission.ethnic.create" v-on:change="changeToggle('ethnics-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="etnias-edit" v-model="permission.ethnic.edit" v-on:change="changeToggle('ethnics-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="etnias-remove" v-model="permission.ethnic.remove" v-on:change="changeToggle('ethnics-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Grupo Sanguíneo</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-sanguineo-list" v-model="permission.blood_group.list" v-on:change="changeToggle('bloodgroups-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-sanguineo-create" v-model="permission.blood_group.create" v-on:change="changeToggle('bloodgroups-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-sanguineo-edit" v-model="permission.blood_group.edit" v-on:change="changeToggle('bloodgroups-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="grupo-sanguineo-remove" v-model="permission.blood_group.remove" v-on:change="changeToggle('bloodgroups-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Linguagens</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="linguagens-list" v-model="permission.language.list" v-on:change="changeToggle('languages-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="linguagens-create" v-model="permission.language.create" v-on:change="changeToggle('languages-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="linguagens-edit" v-model="permission.language.edit" v-on:change="changeToggle('languages-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="linguagens-remove" v-model="permission.language.remove" v-on:change="changeToggle('languages-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Procedimentos</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="procedimentos-list" v-model="permission.procedures.list" v-on:change="changeToggle('procedures-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="procedimentos-create" v-model="permission.procedures.create" v-on:change="changeToggle('procedures-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="procedimentos-edit" v-model="permission.procedures.edit" v-on:change="changeToggle('procedures-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="procedimentos-remove" v-model="permission.procedures.remove" v-on:change="changeToggle('procedures-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Raças</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="racas-list" v-model="permission.races.list" v-on:change="changeToggle('races-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="racas-create" v-model="permission.races.create" v-on:change="changeToggle('races-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="racas-edit" v-model="permission.races.edit" v-on:change="changeToggle('races-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="racas-remove" v-model="permission.races.remove" v-on:change="changeToggle('races-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Religiões</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="religioes-list" v-model="permission.religion.list" v-on:change="changeToggle('religions-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="religioes-create" v-model="permission.religion.create" v-on:change="changeToggle('religions-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="religioes-edit" v-model="permission.religion.edit" v-on:change="changeToggle('religions-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="religioes-remove" v-model="permission.religion.remove" v-on:change="changeToggle('religions-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Signos</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="signos-list" v-model="permission.zodiac_signs.list" v-on:change="changeToggle('zodiacsigns-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="signos-create" v-model="permission.zodiac_signs.create" v-on:change="changeToggle('zodiacsigns-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="signos-edit" v-model="permission.zodiac_signs.edit" v-on:change="changeToggle('zodiacsigns-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="signos-remove" v-model="permission.zodiac_signs.remove" v-on:change="changeToggle('zodiacsigns-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <label>Texturas de Cabelo</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="textura-cabelo-list" v-model="permission.hair_texture.list" v-on:change="changeToggle('hairtextures-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="textura-cabelo-create" v-model="permission.hair_texture.create" v-on:change="changeToggle('hairtextures-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="textura-cabelo-edit" v-model="permission.hair_texture.edit" v-on:change="changeToggle('hairtextures-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="textura-cabelo-remove" v-model="permission.hair_texture.remove" v-on:change="changeToggle('hairtextures-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

            </div><!-- col-md-12 -->
          </div><!-- row -->

          <!-- divider -->
          <hr class="my-4" />
          <!-- Address -->
          <h6 class="heading-small text-muted mb-4">Acessos Especiais</h6>

          <div class="row mt-4">
            <div class="col-md-12">

              <!-- item title -->
              <div class="row">
                <div class="col-md-6">
                  
                </div>
                <div class="col-md-1">
                  <label>Listar</label>
                </div>
                <div class="col-md-1">
                  <label>Criar</label>
                </div>
                <div class="col-md-1">
                  <label>Editar</label>
                </div>
                <div class="col-md-1">
                  <label>Excluir</label>
                </div>
                <div class="col-md-1">
                  <label>Ver Alterações</label>
                </div>
                <div class="col-md-1">
                  <label>Aprovar Alterações</label>
                </div>
              </div>
              <!-- end item title -->

              <div class="row">
                <div class="col-md-6">
                  <label>Doadores</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-list" v-model="permission.donor.list" v-on:change="changeToggle('donors-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-create" v-model="permission.donor.create" v-on:change="changeToggle('donors-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-edit" v-model="permission.donor.edit" v-on:change="changeToggle('donors-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-remove" v-model="permission.donor.remove" v-on:change="changeToggle('donors-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-updates-view" v-model="permission.donor.updates_view" v-on:change="changeToggle('donors-updates-view')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="donors-updates-approve" v-model="permission.donor.updates_approve" v-on:change="changeToggle('donors-updates-approve')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

            </div><!-- col-md-12 -->
          </div><!-- row -->

          <!-- divider -->
          <hr class="my-4" />
          <!-- Address -->
          <div class="row">
            <div class="col-md-12">

              <!-- item title -->
              <div class="row">
                <div class="col-md-8">
                  
                </div>
                <div class="col-md-1">
                  <label>Listar</label>
                </div>
                <div class="col-md-1">
                  <label>Criar</label>
                </div>
                <div class="col-md-1">
                  <label>Baixar Todos</label>
                </div>
                <div class="col-md-1">
                  <label>Baixar Pendentes</label>
                </div>
              </div>
              <!-- end item title -->

              <div class="row">
                <div class="col-md-8">
                  Agendamentos
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="agendamentos-list" v-model="permission.schedule.list" v-on:change="changeToggle('schedules-list')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="agendamentos-create" v-model="permission.schedule.create" v-on:change="changeToggle('schedules-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="agendamentos-download-all" v-model="permission.schedule.download_all" v-on:change="changeToggle('schedules-download-all')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="agendamentos-download-pending" v-model="permission.schedule.download_pending" v-on:change="changeToggle('schedules-download-pending')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

            </div><!-- col-md-12 -->
          </div><!-- row -->

          <!-- divider -->
          <hr class="my-4" />

          <!-- Newsletter -->
          <div class="row">
            <div class="col-md-12">

              <!-- item title -->
              <div class="row">
                <div class="col-md-6">
                  
                </div>
                <div class="col-md-1">
                  <label>Listar</label>
                </div>
                <div class="col-md-1">
                  <label>Criar</label>
                </div>
                <div class="col-md-1">
                  <label>Editar</label>
                </div>
                <div class="col-md-1">
                  <label>Excluir</label>
                </div>
                <div class="col-md-1">
                  <label>Baixar Todos</label>
                </div>
                <div class="col-md-1">
                  <label>Baixar Pendentes</label>
                </div>
              </div>
              <!-- end item title -->

              <div class="row">
                <div class="col-md-6">
                  <label>Newsletters</label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="newsletters-list" v-model="permission.newsletter.list" class="custom-switch-input" v-on:change="changeToggle('newsletter-list')">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="newsletters-create" v-model="permission.newsletter.create" v-on:change="changeToggle('newsletter-create')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="newsletters-edit" v-model="permission.newsletter.edit" v-on:change="changeToggle('newsletter-edit')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="newsletters-remove" v-model="permission.newsletter.remove" v-on:change="changeToggle('newsletter-delete')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="newsletters-download-all" v-model="permission.newsletter.download_all" v-on:change="changeToggle('newsletter-download-all')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
                <div class="col-md-1">
                  <label class="custom-switch">
                    <input type="checkbox" name="newsletters-download-pending" v-model="permission.newsletter.download_pending" v-on:change="changeToggle('newsletter-download-pending')" class="custom-switch-input">
                    <span class="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

            </div><!-- col-md-12 -->
          </div><!-- row -->
          <!-- end form -->

        </div><!-- card body -->
      </div><!-- card -->
    </div><!-- col-12 -->

    <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
  </div><!-- row -->
</template>

<script>
  export default {
    data() {
      return {
        name: '',
        permission: {
          bone_structure: {},
          blood_group: {},
          clinic: {},
          contact: {},
          donor: {},
          doubts: {},
          ethnic: {},
          hair_color: {},
          hair_texture: {},
          eye_color: {},
          language: {},
          media: {},
          news: {},
          newsletter: {},
          permissions: {},
          procedures: {},
          races: {},
          religion: {},
          schedule: {},
          skin_color: {},
          users: {},
          zodiac_signs: {}
        },
        permissions: [],
        errors: [],
        message: '',
        loading: false,
        fullPage: true
      }
    },
    mounted() {
    },
    methods: {
      changeToggle(toggle){
        
        //validate if have permissions
        if (this.permissions.length == 0){

          //push permission
          this.permissions.push(toggle);

        } else {

          //check if permission exists in array
          var check = this.permissions.includes(toggle);

          //validate check
          if (check){

            //remove permission
            this.permissions.pop(toggle);
            
          } else {

            //add permission
            this.permissions.push(toggle);

          }
          //end if validate check

        }
        //end validate if have permissions
      },
      newData() {
        if (this.name == ""){


        } else {

          let _this = this;
          _this.errors = [];
          _this.message = '';
          _this.loading = true;
          let dataUser = {
            'name': _this.name,
            'permission': _this.permission,
            'permissions': _this.permissions
          };
          axios.post('/admin/permissions', dataUser).then((res) => { 

            console.log(res);

            _this.loading = false;
            _this.resetForm();

            //show confirm alert
            _this.$swal({
              title: 'Sucesso',
              text: 'Grupo de Acesso cadastrado com sucesso!',
              icon: 'success'
            });
          }).catch((err) => {

            _this.loading = false;

            //show error alert
            _this.$swal({
              title: 'Erro',
              text: 'Falha ao cadastrar usuário(a). Tente novamente!',
              icon: 'error'
            });
          });

        }
      },
      resetForm() {
        this.name = '';
        this.email = '';
        this.password = '';
      }
    }
  }
</script>
