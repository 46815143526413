<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>

					<a @click="updateData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<ul class="nav nav-pills nav-fill" id="pills-tab" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" id="tab-1" data-toggle="pill" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Dados</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" id="tab-2" data-toggle="pill" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Escolaridade</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" id="tab-3" data-toggle="pill" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">Pai e Mãe</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" id="tab-4" data-toggle="pill" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false">Bisavós e Tataravós</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" id="tab-5" data-toggle="pill" href="#tab5" role="tab" aria-controls="tab5" aria-selected="false">Adicionais</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" id="tab-5" data-toggle="pill" href="#tab6" role="tab" aria-controls="tab6" aria-selected="false">Arquivos do Doador</a>
						</li>
					</ul>

					<div class="tab-content mt-4" id="pills-tabContent">

						<!-- tab 1 -->
						<div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab-1">
							<div class="row">

								<!-- internal_id -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-internal_id">ID Interno</label>
										<input type="text" id="input-internal_id" v-bind:class="{'is-invalid': errors.internal_id}" class="form-control" v-model="internal_id" required="true" disabled>
										<div class="invalid-feedback" v-if="errors.internal_id">
											<p>{{ errors.internal_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-4 -->
								<!-- end internal_id -->

								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-sperm_bank_id">Banco de Sêmen</label>
										<select type="text" id="input-sperm_bank_id" v-bind:class="{'is-invalid': errors.sperm_bank_id}" class="form-control" placeholder="Banco de Sêmen" v-model="sperm_bank_id" required="true" data-toggle="select">
											<option v-for="item in sperm_banks" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.sperm_bank_id">
											<p>{{ errors.sperm_bank_id[0] }}</p>
										</div>
									</div>
								</div>

								<!-- surname -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-surname">Apelido</label>
										<input type="text" id="input-surname" v-bind:class="{'is-invalid': errors.surname}" class="form-control" v-model="surname">
										<div class="invalid-feedback" v-if="errors.surname">
											<p>{{ errors.surname[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-4 -->
								<!-- end surname -->

								<!-- donor_type -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-donor_type">Tipo de Doador</label>
										<select type="text" id="input-donor_type" v-bind:class="{'is-invalid': errors.donor_type}" class="form-control" placeholder="Ativo" v-model="donor_type" required="true" data-toggle="select">
											<option value="">-</option>
											<option value="ID Release">ID Release</option>
											<option value="No ID Release">No ID Release</option>
										</select>
										<div class="invalid-feedback" v-if="errors.donor_type">
											<p>{{ errors.donor_type[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end donor_type -->

								<!-- active -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-active">Status</label>
										<select type="text" id="input-active" v-bind:class="{'is-invalid': errors.active}" class="form-control" placeholder="Ativo" v-model="active" required="true" data-toggle="select">
											<option value="1">Ativo</option>
											<option value="0">Desativado</option>
										</select>
										<div class="invalid-feedback" v-if="errors.active">
											<p>{{ errors.active[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end active -->

								<div class="col-md-6">
									<div class="form-group">
										<label for="input-procedures">Procedimentos Realizados:</label>
										<select id="input-procedures" style="height: 100px;" v-bind:class="{'is-invalid': errors.procedures}" class="form-control" v-model="procedures" multiple="multiple">
											<option value="4">FIV</option>
											<option value="5">ICSI</option>
											<option value="6">ICSI com prep seminal (SP)</option>
											<option value="2">Inseminação</option>
											<option value="3">Inseminação com prep seminal (SP)</option>
										</select>
									</div>
								</div>

								<div class="col-md-6">
									<div class="form-group">
										<label for="ethnic_groups">Grupos étnicos:</label>
										<select id="ethnic_groups" style="height: 100px;" v-bind:class="{'is-invalid': errors.ethnic_groups}" class="form-control" v-model="ethnic_groups" multiple="multiple">
											<option value="42">África</option>
											<option value="8">Alemanha</option>
											<option value="55">América do Norte</option>
											<option value="54">Angola</option>
											<option value="60">Austrália</option>
											<option value="14">Áustria</option>
											<option value="44">Bélgica</option>
											<option value="28">Bolívia</option>
											<option value="4">Brasil</option>
											<option value="33">Búlgaro</option>
											<option value="56">Canadá</option>
											<option value="10">Chile</option>
											<option value="34">China</option>
											<option value="40">Cingapura</option>
											<option value="21">Colômbia</option>
											<option value="35">Coréia</option>
											<option value="57">Dinamarca</option>
											<option value="26">Escócia</option>
											<option value="7">Espanha</option>
											<option value="43">Europa</option>
											<option value="27">França</option>
											<option value="16">Grécia</option>
											<option value="18">Holanda</option>
											<option value="15">Hungria</option>
											<option value="39">Índia</option>
											<option value="46">Indio</option>
											<option value="19">Inglaterra</option>
											<option value="58">Islândia</option>
											<option value="24">Irlanda</option>
											<option value="31">Israel</option>
											<option value="3">Italia</option>
											<option value="38">Iugoslávia</option>
											<option value="22">Japão</option>
											<option value="45">Letônia</option>
											<option value="5">Líbano</option>
											<option value="17">Lituânia</option>
											<option value="48">Negro</option>
											<option value="25">Noruega</option>
											<option value="13">Polônia</option>
											<option value="1">Portugal</option>
											<option value="41">Republica-Tcheca</option>
											<option value="30">Romênia</option>
											<option value="12">Rússia</option>
											<option value="6">Semita</option>
											<option value="32">Síria</option>
											<option value="20">Suiça</option>
											<option value="37">Turquia</option>
											<option value="36">Ucrânia</option>
											<option value="11">Uruguai</option>
											<option value="51">Venezuela</option>
										</select>
									</div>
								</div>

								<!-- gravidez -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-gravidez">Relato de Gravidez</label>
										<select type="text" id="input-gravidez" v-bind:class="{'is-invalid': errors.gravidez}" class="form-control" placeholder="Relato de Gravidez" v-model="gravidez" required="true" data-toggle="select">
											<option value="1">Sim</option>
											<option value="0">Não</option>
										</select>
										<div class="invalid-feedback" v-if="errors.gravidez">
											<p>{{ errors.gravidez[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end gravidez -->

								<!-- cariotipo -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-cariotipo">Cariótipo Não Postado</label>
										<select type="text" id="input-cariotipo" v-bind:class="{'is-invalid': errors.cariotipo}" class="form-control" placeholder="Cariótipo Não Postado" v-model="cariotipo" required="true" data-toggle="select">
											<option value="0">Sem Informação</option>
											<option value="1">46, XY Normal</option>
										</select>
										<div class="invalid-feedback" v-if="errors.cariotipo">
											<p>{{ errors.cariotipo[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end cariotipo -->

								<!-- anemia_falsiforme -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-anemia_falsiforme">Anemia Falciforme</label>
										<select type="text" id="input-anemia_falsiforme" v-bind:class="{'is-invalid': errors.anemia_falsiforme}" class="form-control" placeholder="Anemia Falciforme" v-model="anemia_falsiforme" required="true" data-toggle="select">
											<option value="0">Negativo</option>
											<option value="1">Positivo</option>
										</select>
										<div class="invalid-feedback" v-if="errors.anemia_falsiforme">
											<p>{{ errors.anemia_falsiforme[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end anemia_falsiforme -->

								<!-- talassemia -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-talassemia">Talassemia</label>
										<select type="text" id="input-talassemia" v-bind:class="{'is-invalid': errors.talassemia}" class="form-control" placeholder="Talassemia" v-model="talassemia" required="true" data-toggle="select">
											<option value="0">Negativo</option>
											<option value="1">Positivo</option>
										</select>
										<div class="invalid-feedback" v-if="errors.talassemia">
											<p>{{ errors.talassemia[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end talassemia -->

								<!-- blood_group_id -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-blood_group_id">Grupo Sanguíneo</label>
										<select type="text" id="input-blood_group_id" v-bind:class="{'is-invalid': errors.blood_group_id}" class="form-control" placeholder="Grupo Sanguíneo" v-model="blood_group_id" required="true" data-toggle="select">
											<option v-for="item in bloodgroups" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.blood_group_id">
											<p>{{ errors.blood_group_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end blood_group_id -->

								<!-- hla_c -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-hla_c">HLA-C</label>
										<select type="text" id="input-hla_c" v-bind:class="{'is-invalid': errors.hla_c}" class="form-control" placeholder="HLA-C" v-model="hla_c" required="true" data-toggle="select">
											<option value="N/D">N/D</option>
											<option value="C1C1">C1C1</option>
											<option value="C1C2">C1C2</option>
											<option value="C2C2">C2C2</option>
										</select>
										<div class="invalid-feedback" v-if="errors.hla_c">
											<p>{{ errors.hla_c[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end hla_c -->

								<!-- race_id -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-race_id">Raça</label>
										<select type="text" id="input-race_id" v-bind:class="{'is-invalid': errors.race_id}" class="form-control" placeholder="Raça" v-model="race_id" required="true" data-toggle="select">
											<option v-for="item in races" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.race_id">
											<p>{{ errors.race_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end race_id -->

								<!-- religion_id -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-religion_id">Religião</label>
										<select type="text" id="input-religion_id" v-bind:class="{'is-invalid': errors.religion_id}" class="form-control" placeholder="Religião" v-model="religion_id" required="true" data-toggle="select">
											<option v-for="item in religions" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.religion_id">
											<p>{{ errors.religion_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end religion_id -->

								<!-- hair_color_id -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-hair_color_id">Cor do Cabelo</label>
										<select type="text" id="input-hair_color_id" v-bind:class="{'is-invalid': errors.hair_color_id}" class="form-control" placeholder="Cor do Cabelo" v-model="hair_color_id" required="true" data-toggle="select">
											<option v-for="item in haircolors" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.hair_color_id">
											<p>{{ errors.hair_color_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end hair_color_id -->

								<!-- hair_texture_id -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-hair_texture_id">Textura do Cabelo</label>
										<select type="text" id="input-hair_texture_id" v-bind:class="{'is-invalid': errors.hair_texture_id}" class="form-control" placeholder="Textura do Cabelo" v-model="hair_texture_id" required="true" data-toggle="select">
											<option v-for="item in hairtextures" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.hair_texture_id">
											<p>{{ errors.hair_texture_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end hair_texture_id -->

								<!-- baldness -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-baldness">Calvície</label>
										<select type="text" id="input-baldness" v-bind:class="{'is-invalid': errors.baldness}" class="form-control" placeholder="Calvície" v-model="baldness" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Sim</option>
											<option value="2">Não</option>
										</select>
										<div class="invalid-feedback" v-if="errors.baldness">
											<p>{{ errors.baldness[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end baldness -->

								<!-- skin_color_id -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-skin_color_id">Cor de Pele</label>
										<select type="text" id="input-skin_color_id" v-bind:class="{'is-invalid': errors.skin_color_id}" class="form-control" placeholder="Cor de Pele" v-model="skin_color_id" required="true" data-toggle="select">
											<option v-for="item in skincolors" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.skin_color_id">
											<p>{{ errors.skin_color_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end skin_color_id -->

								<!-- sunburn -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-sunburn">Sua pele quando queimada pelo sol</label>
										<select type="text" id="input-sunburn" v-bind:class="{'is-invalid': errors.sunburn}" class="form-control" placeholder="Sua pele quando queimada pelo sol" v-model="sunburn" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Avermelhada</option>
											<option value="2">Bronzeado Leve</option>
											<option value="3">Bronzeado Escuro</option>
											<option value="4">Marrom / Preto</option>
										</select>
										<div class="invalid-feedback" v-if="errors.sunburn">
											<p>{{ errors.sunburn[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end sunburn -->

								<!-- freckles -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-freckles">Sardas</label>
										<select type="text" id="input-freckles" v-bind:class="{'is-invalid': errors.freckles}" class="form-control" placeholder="Sardas" v-model="freckles" required="true" data-toggle="select">
											<option value="0"> Não Informado </option>
											<option value="1"> Nenhuma </option>
											<option value="2"> Algumas </option>
											<option value="3"> Muitas </option>
										</select>
										<div class="invalid-feedback" v-if="errors.freckles">
											<p>{{ errors.freckles[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end freckles -->

								<!-- nose -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-nose">Nariz</label>
										<select type="text" id="input-nose" v-bind:class="{'is-invalid': errors.nose}" class="form-control" placeholder="Nariz" v-model="nose" required="true" data-toggle="select">
											<option value="0"> Não Informado </option>
											<option value="1"> Fino arrebitado </option>
											<option value="2"> Curto ou asiático </option>
											<option value="3"> Núbio </option>
											<option value="4"> Romano </option>
											<option value="5"> Outro </option>
										</select>
										<div class="invalid-feedback" v-if="errors.nose">
											<p>{{ errors.nose[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end nose -->

								<!-- other_nose -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-other_nose">Caso 'Outro' descreva</label>
										<input type="text" id="input-other_nose" v-bind:class="{'is-invalid': errors.other_nose}" class="form-control" v-model="other_nose" required="true">
										<div class="invalid-feedback" v-if="errors.other_nose">
											<p>{{ errors.other_nose[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end other_nose -->

								<!-- nostrils -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-nostrils">Narinas</label>
										<select type="text" id="input-nostrils" v-bind:class="{'is-invalid': errors.nostrils}" class="form-control" placeholder="Narinas" v-model="nostrils" required="true" data-toggle="select">
											<option value="0"> Não Informado </option>
											<option value="1"> Estreitas </option>
											<option value="2"> Largas </option>
											<option value="3"> Normais </option>
										</select>
										<div class="invalid-feedback" v-if="errors.nostrils">
											<p>{{ errors.nostrils[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end nostrils -->

								<!-- lips -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-lips">Lábios</label>
										<select type="text" id="input-lips" v-bind:class="{'is-invalid': errors.lips}" class="form-control" placeholder="Lábios" v-model="lips" required="true" data-toggle="select">
											<option value="0"> Não Informado </option>
											<option value="1"> Grosso </option>
											<option value="2"> Médio </option>
											<option value="3"> Fino </option>
										</select>
										<div class="invalid-feedback" v-if="errors.lips">
											<p>{{ errors.lips[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end lips -->

								<!-- face_shape -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-face_shape">Formato do Rosto</label>
										<select type="text" id="input-face_shape" v-bind:class="{'is-invalid': errors.face_shape}" class="form-control" placeholder="Formato do Rosto" v-model="face_shape" required="true" data-toggle="select">
											<option value="0"> Não Informado </option>
											<option value="1"> Quadrado </option>
											<option value="2"> Redondo </option>
											<option value="3"> Oval </option>
											<option value="4"> Fino </option>
											<option value="5"> Retangular </option>
											<option value="6"> Triangular </option>
										</select>
										<div class="invalid-feedback" v-if="errors.face_shape">
											<p>{{ errors.face_shape[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end face_shape -->

								<!-- particularities -->
								<div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label" for="input-particularities">Particularidades</label>
										<select type="text" id="input-particularities" v-bind:class="{'is-invalid': errors.particularities}" class="form-control" placeholder="Particularidades" v-model="particularities" required="true" data-toggle="select">
											<option value="0">Nenhum</option>
											<option value="1">Covinhas</option>
											<option value="2">Furo no Queixo</option>
										</select>
										<div class="invalid-feedback" v-if="errors.particularities">
											<p>{{ errors.particularities[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end particularities -->

								<!-- personality -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-personality">Fale sobre sua personalidade</label>
										<input type="text" id="input-personality" v-bind:class="{'is-invalid': errors.personality}" class="form-control" v-model="personality" required="true">
										<div class="invalid-feedback" v-if="errors.personality">
											<p>{{ errors.personality[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end personality -->

								<!-- movies -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-movies">Tipos de Filme</label>
										<input type="text" id="input-movies" v-bind:class="{'is-invalid': errors.movies}" class="form-control" v-model="movies" required="true">
										<div class="invalid-feedback" v-if="errors.movies">
											<p>{{ errors.movies[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end movies -->

								<!-- eye_surgery -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-eye_surgery">Fez cirurgia ocular</label>
										<select type="text" id="input-eye_surgery" v-bind:class="{'is-invalid': errors.eye_surgery}" class="form-control" placeholder="Fez cirurgia ocular" v-model="eye_surgery" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Sim</option>
											<option value="2">Não</option>
										</select>
										<div class="invalid-feedback" v-if="errors.eye_surgery">
											<p>{{ errors.eye_surgery[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end eye_surgery -->

								<!-- negros_family -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-negros_family">Negros na família</label>
										<select type="text" id="input-negros_family" v-bind:class="{'is-invalid': errors.negros_family}" class="form-control" placeholder="Negros na família" v-model="negros_family" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Sim</option>
											<option value="2">Não</option>
											<option value="3">Não Sabe</option>
										</select>
										<div class="invalid-feedback" v-if="errors.negros_family">
											<p>{{ errors.negros_family[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end negros_family -->

								<!-- negros_family_degree -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-negros_family_degree">Grau de parentesco</label>
										<input type="text" id="input-negros_family_degree" v-bind:class="{'is-invalid': errors.negros_family_degree}" class="form-control" v-model="negros_family_degree" required="true">
										<div class="invalid-feedback" v-if="errors.negros_family_degree">
											<p>{{ errors.negros_family_degree[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end negros_family_degree -->

								<!-- eye_color_id -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-eye_color_id">Cor dos Olhos</label>
										<select type="text" id="input-eye_color_id" v-bind:class="{'is-invalid': errors.eye_color_id}" class="form-control" placeholder="Cor dos Olhos" v-model="eye_color_id" required="true" data-toggle="select">
											<option v-for="item in eyecolors" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.eye_color_id">
											<p>{{ errors.eye_color_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end eye_color_id -->

								<!-- bone_structure_id -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-bone_structure_id">Estrutura Óssea</label>
										<select type="text" id="input-bone_structure_id" v-bind:class="{'is-invalid': errors.bone_structure_id}" class="form-control" placeholder="Estrutura Óssea" v-model="bone_structure_id" required="true" data-toggle="select">
											<option v-for="item in bonestructures" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.bone_structure_id">
											<p>{{ errors.bone_structure_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end bone_structure_id -->

								<!-- age -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-age">Idade</label>
										<input type="number" id="input-age" v-bind:class="{'is-invalid': errors.age}" class="form-control" v-model="age" required="true">
										<div class="invalid-feedback" v-if="errors.age">
											<p>{{ errors.age[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end age -->

								<!-- height -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-height">Altura (em cm)</label>
										<input type="number" id="input-height" v-bind:class="{'is-invalid': errors.height}" class="form-control" v-model="height" required="true">
										<div class="invalid-feedback" v-if="errors.height">
											<p>{{ errors.height[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end height -->

								<!-- weight -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-weight">Peso (em kg)</label>
										<input type="number" id="input-weight" v-bind:class="{'is-invalid': errors.weight}" class="form-control" v-model="weight" required="true">
										<div class="invalid-feedback" v-if="errors.weight">
											<p>{{ errors.weight[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end weight -->

								<!-- occupation -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-occupation">Ocupação (separadas por vírgula)</label>
										<input type="text" id="input-occupation" v-bind:class="{'is-invalid': errors.occupation}" class="form-control" v-model="occupation" required="true">
										<div class="invalid-feedback" v-if="errors.occupation">
											<p>{{ errors.occupation[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end occupation -->

								<!-- hobby -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-hobby">Hobby (separados por vírgula)</label>
										<input type="text" id="input-hobby" v-bind:class="{'is-invalid': errors.hobby}" class="form-control" v-model="hobby" required="true">
										<div class="invalid-feedback" v-if="errors.hobby">
											<p>{{ errors.hobby[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end hobby -->

								<!-- zodiac_sign_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-zodiac_sign_id">Signo</label>
										<select type="text" id="input-zodiac_sign_id" v-bind:class="{'is-invalid': errors.zodiac_sign_id}" class="form-control" placeholder="Signo" v-model="zodiac_sign_id" required="true" data-toggle="select">
											<option v-for="item in zodiacsigns" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.zodiac_sign_id">
											<p>{{ errors.zodiac_sign_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end zodiac_sign_id -->

								<div class="col-md-6">
									<div class="form-group">
										<label for="languages">Idiomas:</label>
										<select id="languages" v-bind:class="{'is-invalid': errors.languages}" v-model="languages" class="form-control" multiple="multiple">
											<option value="3" >Alemão</option>
											<option value="4" >Chines</option>
											<option value="5" >Espanhol</option>
											<option value="6" >Francês</option>
											<option value="7" >Hebraico</option>
											<option value="8" >Holandês</option>
											<option value="1" >Inglês</option>
											<option value="9" >Italiano</option>
											<option value="2" >Português</option>
											<option value="10" >Russo</option>
											<option value="11" >Japonês</option>
										</select>
									</div>
								</div>

								<!-- food -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-food">Comida (separadas por vírgula)</label>
										<input type="text" id="input-food" v-bind:class="{'is-invalid': errors.food}" class="form-control" v-model="food" required="true">
										<div class="invalid-feedback" v-if="errors.food">
											<p>{{ errors.food[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end food -->

								<!-- color -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-color">Cores (separadas por vírgula)</label>
										<input type="text" id="input-color" v-bind:class="{'is-invalid': errors.color}" class="form-control" v-model="color" required="true">
										<div class="invalid-feedback" v-if="errors.color">
											<p>{{ errors.color[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end color -->

								<!-- animal -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-animal">Animais (separados por vírgula)</label>
										<input type="text" id="input-animal" v-bind:class="{'is-invalid': errors.animal}" class="form-control" v-model="animal" required="true">
										<div class="invalid-feedback" v-if="errors.animal">
											<p>{{ errors.animal[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end animal -->

								<!-- music -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-music">Música (separadas por vírgula)</label>
										<input type="text" id="input-music" v-bind:class="{'is-invalid': errors.music}" class="form-control" v-model="music" required="true">
										<div class="invalid-feedback" v-if="errors.music">
											<p>{{ errors.music[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end music -->

								<!-- visually_impaired -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-visually_impaired">Deficiência Visual (separadas por vírgula)</label>
										<input type="text" id="input-visually_impaired" v-bind:class="{'is-invalid': errors.visually_impaired}" class="form-control" v-model="visually_impaired" required="true">
										<div class="invalid-feedback" v-if="errors.visually_impaired">
											<p>{{ errors.visually_impaired[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end visually_impaired -->

								<!-- allergy -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-allergy">Alergias (separadas por vírgula)</label>
										<input type="text" id="input-allergy" v-bind:class="{'is-invalid': errors.allergy}" class="form-control" v-model="allergy" required="true">
										<div class="invalid-feedback" v-if="errors.allergy">
											<p>{{ errors.allergy[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end allergy -->

								<!-- physical_activities -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-physical_activities">Atividades físicas (separadas por vírgula)</label>
										<input type="text" id="input-physical_activities" v-bind:class="{'is-invalid': errors.physical_activities}" class="form-control" v-model="physical_activities" required="true">
										<div class="invalid-feedback" v-if="errors.physical_activities">
											<p>{{ errors.physical_activities[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end physical_activities -->

								<!-- supplemental_diet -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-supplemental_diet">Dieta suplementar (separadas por vírgula)</label>
										<input type="text" id="input-supplemental_diet" v-bind:class="{'is-invalid': errors.supplemental_diet}" class="form-control" v-model="supplemental_diet" required="true">
										<div class="invalid-feedback" v-if="errors.supplemental_diet">
											<p>{{ errors.supplemental_diet[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end supplemental_diet -->

								<!-- hearing_impairment -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-hearing_impairment">Deficiência Auditiva</label>
										<select type="text" id="input-hearing_impairment" v-bind:class="{'is-invalid': errors.hearing_impairment}" class="form-control" placeholder="Deficiência Auditiva" v-model="hearing_impairment" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Sim</option>
											<option value="2">Não</option>
										</select>
										<div class="invalid-feedback" v-if="errors.hearing_impairment">
											<p>{{ errors.hearing_impairment[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end hearing_impairment -->

								<!-- smoking -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-smoking">Fumante</label>
										<select type="text" id="input-smoking" v-bind:class="{'is-invalid': errors.smoking}" class="form-control" placeholder="Fumante" v-model="smoking" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Sim</option>
											<option value="2">Não</option>
										</select>
										<div class="invalid-feedback" v-if="errors.smoking">
											<p>{{ errors.smoking[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end smoking -->

								<!-- trip -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-trip">Viagem</label>
										<select type="text" id="input-trip" v-bind:class="{'is-invalid': errors.trip}" class="form-control" placeholder="Viagem" v-model="trip" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Sim</option>
											<option value="2">Não</option>
										</select>
										<div class="invalid-feedback" v-if="errors.trip">
											<p>{{ errors.trip[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end trip -->

								<!-- military_service -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-military_service">Serviço Militar</label>
										<select type="text" id="input-military_service" v-bind:class="{'is-invalid': errors.military_service}" class="form-control" placeholder="Serviço Militar" v-model="military_service" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Sim</option>
											<option value="2">Não</option>
										</select>
										<div class="invalid-feedback" v-if="errors.military_service">
											<p>{{ errors.military_service[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end military_service -->

								<!-- dentist_regularly -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-dentist_regularly">Vai ao dentista regularmente?</label>
										<select type="text" id="input-dentist_regularly" v-bind:class="{'is-invalid': errors.dentist_regularly}" class="form-control" placeholder="Vai ao dentista regularmente?" v-model="dentist_regularly" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Sim</option>
											<option value="2">Não</option>
										</select>
										<div class="invalid-feedback" v-if="errors.dentist_regularly">
											<p>{{ errors.dentist_regularly[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end dentist_regularly -->

								<!-- twins_in_family -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-twins_in_family">Gêmeos na família?</label>
										<select type="text" id="input-twins_in_family" v-bind:class="{'is-invalid': errors.twins_in_family}" class="form-control" placeholder="Gêmeos na família?" v-model="twins_in_family" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Sim</option>
											<option value="2">Não</option>
										</select>
										<div class="invalid-feedback" v-if="errors.twins_in_family">
											<p>{{ errors.twins_in_family[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end twins_in_family -->

							</div>
						</div>
						<!-- end tab 1 -->

						<!-- tab 2 -->
						<div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab-2">
							<div class="row">

								<!-- school -->
								<!-- <div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-school">Educação</label>
										<input type="text" id="input-school" v-bind:class="{'is-invalid': errors.school}" class="form-control" placeholder="Educação" v-model="school" required="true">
										<div class="invalid-feedback" v-if="errors.school">
											<p>{{ errors.school[0] }}</p>
										</div>
									</div>
								</div> -->
								<!-- end school -->

								<!-- school_name -->
								<!-- <div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-school_name">Nome da Escola</label>
										<input type="text" id="input-school_name" v-bind:class="{'is-invalid': errors.school_name}" class="form-control" placeholder="Nome da Escola" v-model="school_name" required="true">
										<div class="invalid-feedback" v-if="errors.school_name">
											<p>{{ errors.school_name[0] }}</p>
										</div>
									</div>
								</div> -->
								<!-- end school_name -->

								<!-- first_degree_complete -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-first_degree_complete">1º Grau Completo</label>
										<select type="text" id="input-first_degree_complete" v-bind:class="{'is-invalid': errors.first_degree_complete}" class="form-control" placeholder="1º Grau Completo" v-model="first_degree_complete" required="true">
											<option value="">Não informado</option>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
										<div class="invalid-feedback" v-if="errors.first_degree_complete">
											<p>{{ errors.first_degree_complete[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end first_degree_complete -->

								<!-- first_degree_incomplete -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-first_degree_incomplete">1º Grau Incompleto</label>
										<select type="text" id="input-first_degree_incomplete" v-bind:class="{'is-invalid': errors.first_degree_incomplete}" class="form-control" placeholder="1º Grau Incompleto" v-model="first_degree_incomplete" required="true">
											<option value="">Não informado</option>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
										<div class="invalid-feedback" v-if="errors.first_degree_incomplete">
											<p>{{ errors.first_degree_incomplete[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end first_degree_incomplete -->

								<!-- second_degree_complete -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-second_degree_complete">2º Grau Completo</label>
										<select type="text" id="input-second_degree_complete" v-bind:class="{'is-invalid': errors.second_degree_complete}" class="form-control" placeholder="2º Grau Completo" v-model="second_degree_complete" required="true">
											<option value="">Não informado</option>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
										<div class="invalid-feedback" v-if="errors.second_degree_complete">
											<p>{{ errors.second_degree_complete[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end second_degree_complete -->

								<!-- second_degree_incomplete -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-second_degree_incomplete">2º Grau Incompleto</label>
										<select type="text" id="input-second_degree_incomplete" v-bind:class="{'is-invalid': errors.second_degree_incomplete}" class="form-control" placeholder="2º Grau Incompleto" v-model="second_degree_incomplete" required="true">
											<option value="">Não informado</option>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
										<div class="invalid-feedback" v-if="errors.second_degree_incomplete">
											<p>{{ errors.second_degree_incomplete[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end second_degree_incomplete -->

								<!-- superior_complete -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-superior_complete">Superior Completo</label>
										<select type="text" id="input-superior_complete" v-bind:class="{'is-invalid': errors.superior_complete}" class="form-control" placeholder="Superior Completo" v-model="superior_complete" required="true">
											<option value="">Não informado</option>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
										<div class="invalid-feedback" v-if="errors.superior_complete">
											<p>{{ errors.superior_complete[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end superior_complete -->

								<!-- superior_complete_name -->
								<div class="col-md-9">
									<div class="form-group">
										<label class="form-control-label" for="input-superior_complete_name">Superior Completo (Curso)</label>
										<input type="text" id="input-superior_complete_name" v-bind:class="{'is-invalid': errors.superior_complete_name}" class="form-control" v-model="superior_complete_name" required="true">
										<div class="invalid-feedback" v-if="errors.superior_complete_name">
											<p>{{ errors.superior_complete_name[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end superior_complete_name -->

								<!-- superior_incomplete -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-superior_incomplete">Superior Incompleto</label>
										<select type="text" id="input-superior_incomplete" v-bind:class="{'is-invalid': errors.superior_incomplete}" class="form-control" placeholder="Superior Incompleto" v-model="superior_incomplete" required="true">
											<option value="">Não informado</option>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
										<div class="invalid-feedback" v-if="errors.superior_incomplete">
											<p>{{ errors.superior_incomplete[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end superior_incomplete -->

								<!-- superior_incomplete_name -->
								<div class="col-md-9">
									<div class="form-group">
										<label class="form-control-label" for="input-superior_incomplete_name">Superior Incompleto (Curso)</label>
										<input type="text" id="input-superior_incomplete_name" v-bind:class="{'is-invalid': errors.superior_incomplete_name}" class="form-control" v-model="superior_incomplete_name" required="true">
										<div class="invalid-feedback" v-if="errors.superior_incomplete_name">
											<p>{{ errors.superior_incomplete_name[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end superior_incomplete_name -->

								<!-- mba -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-mba">MBA</label>
										<select type="text" id="input-mba" v-bind:class="{'is-invalid': errors.mba}" class="form-control" placeholder="MBA" v-model="mba" required="true">
											<option value="">Não informado</option>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
										<div class="invalid-feedback" v-if="errors.mba">
											<p>{{ errors.mba[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mba -->

								<!-- mba_name -->
								<div class="col-md-9">
									<div class="form-group">
										<label class="form-control-label" for="input-mba_name">MBA (Curso)</label>
										<input type="text" id="input-mba_name" v-bind:class="{'is-invalid': errors.mba_name}" class="form-control" v-model="mba_name" required="true">
										<div class="invalid-feedback" v-if="errors.mba_name">
											<p>{{ errors.mba_name[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mba_name -->

								<!-- phd -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-phd">Ph.D</label>
										<select type="text" id="input-phd" v-bind:class="{'is-invalid': errors.phd}" class="form-control" placeholder="Ph.D" v-model="phd" required="true">
											<option value="">Não informado</option>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
										<div class="invalid-feedback" v-if="errors.phd">
											<p>{{ errors.phd[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end phd -->

								<!-- phd_name -->
								<div class="col-md-9">
									<div class="form-group">
										<label class="form-control-label" for="input-phd_name">Ph.D (Curso)</label>
										<input type="text" id="input-phd_name" v-bind:class="{'is-invalid': errors.phd_name}" class="form-control" v-model="phd_name" required="true">
										<div class="invalid-feedback" v-if="errors.phd_name">
											<p>{{ errors.phd_name[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end phd_name -->

								<!-- others -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-others">Outros Cursos</label>
										<select type="text" id="input-others" v-bind:class="{'is-invalid': errors.others}" class="form-control" placeholder="Outros Cursos" v-model="others" required="true">
											<option value="">Não informado</option>
											<option value="0">Não</option>
											<option value="1">Sim</option>
										</select>
										<div class="invalid-feedback" v-if="errors.others">
											<p>{{ errors.others[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end others -->

								<!-- others_name -->
								<div class="col-md-9">
									<div class="form-group">
										<label class="form-control-label" for="input-others_name">Outros Cursos (Nome)</label>
										<input type="text" id="input-others_name" v-bind:class="{'is-invalid': errors.others_name}" class="form-control" v-model="others_name" required="true">
										<div class="invalid-feedback" v-if="errors.others_name">
											<p>{{ errors.others_name[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end others_name -->

							</div>
						</div>
						<!-- end tab 2 -->

						<!-- tab 3 -->
						<div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab-3">
							<div class="row">

								<div class="col-md-12">
									<h6 class="heading-small text-muted mb-4">Pai</h6>
								</div>

								<!-- father_nose -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_nose">Nariz</label>
										<select type="text" id="input-father_nose" v-bind:class="{'is-invalid': errors.father_nose}" class="form-control" placeholder="Nariz" v-model="father_nose" required="true" data-toggle="select">
											<option value="0"> Não Informado </option>
											<option value="1"> Fino arrebitado </option>
											<option value="2"> Curto ou asiático </option>
											<option value="3"> Núbio </option>
											<option value="4"> Romano </option>
											<option value="5"> Outro </option>
										</select>
										<div class="invalid-feedback" v-if="errors.father_nose">
											<p>{{ errors.father_nose[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_nose -->

								<!-- father_other_nose -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_other_nose">Outro tipo de Nariz</label>
										<input type="text" id="input-father_other_nose" v-bind:class="{'is-invalid': errors.father_other_nose}" class="form-control" v-model="father_other_nose" required="true">
										<div class="invalid-feedback" v-if="errors.father_other_nose">
											<p>{{ errors.father_other_nose[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_other_nose -->

								<!-- father_nostrils -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_nostrils">Narinas</label>
										<select type="text" id="input-father_nostrils" v-bind:class="{'is-invalid': errors.father_nostrils}" class="form-control" placeholder="Narinas" v-model="father_nostrils" required="true" data-toggle="select">
											<option value="0"> Não Informado </option>
											<option value="1"> Estreitas </option>
											<option value="2"> Largas </option>
											<option value="3"> Normais </option>
										</select>
										<div class="invalid-feedback" v-if="errors.father_nostrils">
											<p>{{ errors.father_nostrils[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_nostrils -->

								<!-- father_lips -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_lips">Lábios</label>
										<select type="text" id="input-father_lips" v-bind:class="{'is-invalid': errors.father_lips}" class="form-control" placeholder="Lábios" v-model="father_lips" required="true" data-toggle="select">
											<option value="0"> Não Informado </option>
											<option value="1"> Grosso </option>
											<option value="2"> Médio </option>
											<option value="3"> Fino </option>
										</select>
										<div class="invalid-feedback" v-if="errors.father_lips">
											<p>{{ errors.father_lips[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_lips -->

								<!-- father_country -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_country">País de Origem do Pai</label>
										<input type="text" id="input-father_country" v-bind:class="{'is-invalid': errors.father_country}" class="form-control" v-model="father_country" required="true">
										<div class="invalid-feedback" v-if="errors.father_country">
											<p>{{ errors.father_country[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_country -->

								<!-- father_skin_color_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_skin_color_id">Cor de Pele</label>
										<select type="text" id="input-father_skin_color_id" v-bind:class="{'is-invalid': errors.father_skin_color_id}" class="form-control" placeholder="Cor de Pele" v-model="father_skin_color_id" required="true" data-toggle="select">
											<option v-for="item in skincolors" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.father_skin_color_id">
											<p>{{ errors.father_skin_color_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_skin_color_id -->

								<!-- father_eye_color_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_eye_color_id">Cor dos Olhos</label>
										<select type="text" id="input-father_eye_color_id" v-bind:class="{'is-invalid': errors.father_eye_color_id}" class="form-control" placeholder="Cor dos Olhos" v-model="father_eye_color_id" required="true" data-toggle="select">
											<option v-for="item in eyecolors" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.father_eye_color_id">
											<p>{{ errors.father_eye_color_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_eye_color_id -->

								<!-- father_hair_color_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_hair_color_id">Cor do Cabelo</label>
										<select type="text" id="input-father_hair_color_id" v-bind:class="{'is-invalid': errors.father_hair_color_id}" class="form-control" placeholder="Cor do Cabelo" v-model="father_hair_color_id" required="true" data-toggle="select">
											<option v-for="item in haircolors" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.father_hair_color_id">
											<p>{{ errors.father_hair_color_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_hair_color_id -->

								<!-- father_hair_texture_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_hair_texture_id">Textura do Cabelo</label>
										<select type="text" id="input-father_hair_texture_id" v-bind:class="{'is-invalid': errors.father_hair_texture_id}" class="form-control" placeholder="Textura do Cabelo" v-model="father_hair_texture_id" required="true" data-toggle="select">
											<option v-for="item in hairtextures" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.father_hair_texture_id">
											<p>{{ errors.father_hair_texture_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_hair_texture_id -->

								<!-- father_baldness -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_baldness">Calvície</label>
										<select type="text" id="input-father_baldness" v-bind:class="{'is-invalid': errors.father_baldness}" class="form-control" placeholder="Calvície" v-model="father_baldness" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Sim</option>
											<option value="2">Não</option>
										</select>
										<div class="invalid-feedback" v-if="errors.father_baldness">
											<p>{{ errors.father_baldness[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_baldness -->

								<hr/>

								<div class="col-md-12">
									<h6 class="heading-small text-muted mb-4">Mãe</h6>
								</div>

								<!-- mother_nose -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_nose">Nariz</label>
										<select type="text" id="input-mother_nose" v-bind:class="{'is-invalid': errors.mother_nose}" class="form-control" placeholder="Nariz" v-model="mother_nose" required="true" data-toggle="select">
											<option value="0"> Não Informado </option>
											<option value="1"> Fino arrebitado </option>
											<option value="2"> Curto ou asiático </option>
											<option value="3"> Núbio </option>
											<option value="4"> Romano </option>
											<option value="5"> Outro </option>
										</select>
										<div class="invalid-feedback" v-if="errors.mother_nose">
											<p>{{ errors.mother_nose[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_nose -->

								<!-- mother_other_nose -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_other_nose">Outro tipo de Nariz</label>
										<input type="text" id="input-mother_other_nose" v-bind:class="{'is-invalid': errors.mother_other_nose}" class="form-control" v-model="mother_other_nose" required="true">
										<div class="invalid-feedback" v-if="errors.mother_other_nose">
											<p>{{ errors.mother_other_nose[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_other_nose -->

								<!-- mother_nostrils -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_nostrils">Narinas</label>
										<select type="text" id="input-mother_nostrils" v-bind:class="{'is-invalid': errors.mother_nostrils}" class="form-control" placeholder="Narinas" v-model="mother_nostrils" required="true" data-toggle="select">
											<option value="0"> Não Informado </option>
											<option value="1"> Estreitas </option>
											<option value="2"> Largas </option>
											<option value="3"> Normais </option>
										</select>
										<div class="invalid-feedback" v-if="errors.mother_nostrils">
											<p>{{ errors.mother_nostrils[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_nostrils -->

								<!-- mother_lips -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_lips">Lábios</label>
										<select type="text" id="input-mother_lips" v-bind:class="{'is-invalid': errors.mother_lips}" class="form-control" placeholder="Lábios" v-model="mother_lips" required="true" data-toggle="select">
											<option value="0"> Não Informado </option>
											<option value="1"> Grosso </option>
											<option value="2"> Médio </option>
											<option value="3"> Fino </option>
										</select>
										<div class="invalid-feedback" v-if="errors.mother_lips">
											<p>{{ errors.mother_lips[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_lips -->

								<!-- mother_country -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_country">País de Origem da mãe</label>
										<input type="text" id="input-mother_country" v-bind:class="{'is-invalid': errors.mother_country}" class="form-control" v-model="mother_country" required="true">
										<div class="invalid-feedback" v-if="errors.mother_country">
											<p>{{ errors.mother_country[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_country -->

								<!-- mother_skin_color_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_skin_color_id">Cor de Pele</label>
										<select type="text" id="input-mother_skin_color_id" v-bind:class="{'is-invalid': errors.mother_skin_color_id}" class="form-control" placeholder="Cor de Pele" v-model="mother_skin_color_id" required="true" data-toggle="select">
											<option v-for="item in skincolors" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.mother_skin_color_id">
											<p>{{ errors.mother_skin_color_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_skin_color_id -->

								<!-- mother_eye_color_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_eye_color_id">Cor dos Olhos</label>
										<select type="text" id="input-mother_eye_color_id" v-bind:class="{'is-invalid': errors.mother_eye_color_id}" class="form-control" placeholder="Cor dos Olhos" v-model="mother_eye_color_id" required="true" data-toggle="select">
											<option v-for="item in eyecolors" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.mother_eye_color_id">
											<p>{{ errors.mother_eye_color_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_eye_color_id -->

								<!-- mother_hair_color_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_hair_color_id">Cor do Cabelo</label>
										<select type="text" id="input-mother_hair_color_id" v-bind:class="{'is-invalid': errors.mother_hair_color_id}" class="form-control" placeholder="Cor do Cabelo" v-model="mother_hair_color_id" required="true" data-toggle="select">
											<option v-for="item in haircolors" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.mother_hair_color_id">
											<p>{{ errors.mother_hair_color_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_hair_color_id -->

								<!-- mother_hair_texture_id -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_hair_texture_id">Textura do Cabelo</label>
										<select type="text" id="input-mother_hair_texture_id" v-bind:class="{'is-invalid': errors.mother_hair_texture_id}" class="form-control" placeholder="Textura do Cabelo" v-model="mother_hair_texture_id" required="true" data-toggle="select">
											<option v-for="item in hairtextures" :value="item.id" :key="item.id">{{item.name}}</option>
										</select>
										<div class="invalid-feedback" v-if="errors.mother_hair_texture_id">
											<p>{{ errors.mother_hair_texture_id[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_hair_texture_id -->

								<!-- mother_baldness -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_baldness">Calvície</label>
										<select type="text" id="input-mother_baldness" v-bind:class="{'is-invalid': errors.mother_baldness}" class="form-control" placeholder="Calvície" v-model="mother_baldness" required="true" data-toggle="select">
											<option value="0">Não Informado</option>
											<option value="1">Sim</option>
											<option value="2">Não</option>
										</select>
										<div class="invalid-feedback" v-if="errors.mother_baldness">
											<p>{{ errors.mother_baldness[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_baldness -->

							</div>
						</div>
						<!-- end tab 3 -->

						<!-- tab 4 -->
						<div class="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab-4">
							<div class="row">

								<!-- father_father_country -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_father_country">País de Origem do avô paterno</label>
										<input type="text" id="input-father_father_country" v-bind:class="{'is-invalid': errors.father_father_country}" class="form-control" v-model="father_father_country" required="true">
										<div class="invalid-feedback" v-if="errors.father_father_country">
											<p>{{ errors.father_father_country[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_father_country -->

								<!-- father_mother_country -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-father_mother_country">País de Origem da avó paterna</label>
										<input type="text" id="input-father_mother_country" v-bind:class="{'is-invalid': errors.father_mother_country}" class="form-control" v-model="father_mother_country" required="true">
										<div class="invalid-feedback" v-if="errors.father_mother_country">
											<p>{{ errors.father_mother_country[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end father_mother_country -->

								<!-- mother_father_country -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_father_country">País de Origem do avô materno</label>
										<input type="text" id="input-mother_father_country" v-bind:class="{'is-invalid': errors.mother_father_country}" class="form-control" v-model="mother_father_country" required="true">
										<div class="invalid-feedback" v-if="errors.mother_father_country">
											<p>{{ errors.mother_father_country[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_father_country -->

								<!-- mother_mother_country -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-mother_mother_country">País de Origem da avó materna</label>
										<input type="text" id="input-mother_mother_country" v-bind:class="{'is-invalid': errors.mother_mother_country}" class="form-control" v-model="mother_mother_country" required="true">
										<div class="invalid-feedback" v-if="errors.mother_mother_country">
											<p>{{ errors.mother_mother_country[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end mother_mother_country -->

								<!-- great_grandparents -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-great_grandparents">Bisavós</label>
										<input type="text" id="input-great_grandparents" v-bind:class="{'is-invalid': errors.great_grandparents}" class="form-control" v-model="great_grandparents" required="true">
										<div class="invalid-feedback" v-if="errors.great_grandparents">
											<p>{{ errors.great_grandparents[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end great_grandparents -->

								<!-- great_great_grandparents -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-great_great_grandparents">Tataravós</label>
										<input type="text" id="input-great_great_grandparents" v-bind:class="{'is-invalid': errors.great_great_grandparents}" class="form-control" v-model="great_great_grandparents" required="true">
										<div class="invalid-feedback" v-if="errors.great_great_grandparents">
											<p>{{ errors.great_great_grandparents[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end great_great_grandparents -->

							</div>
						</div>
						<!-- end tab 4 -->

						<!-- tab 5 -->
						<div class="tab-pane fade" id="tab5" role="tabpanel" aria-labelledby="tab-5">
							<div class="row">

								<!-- team_vision -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-team_vision">O doador pela visão da equipe Pro-seed</label>
										<textarea type="text" id="input-team_vision" v-bind:class="{'is-invalid': errors.team_vision}" class="form-control" v-model="team_vision" rows="8" required="true"></textarea>
										<div class="invalid-feedback" v-if="errors.team_vision">
											<p>{{ errors.team_vision[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end team_vision -->

								<!-- donor_story -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-donor_story">Relato do doador</label>
										<textarea type="text" id="input-donor_story" v-bind:class="{'is-invalid': errors.donor_story}" class="form-control" v-model="donor_story" rows="8" required="true"></textarea>
										<div class="invalid-feedback" v-if="errors.donor_story">
											<p>{{ errors.donor_story[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end donor_story -->

								<!-- interest -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-interest">O QUE TE DESPERTOU O INTERESSE EM SE TORNAR UM CANDIDATO A DOADOR DE SÊMEN?</label>
										<textarea type="text" id="input-interest" v-bind:class="{'is-invalid': errors.interest}" class="form-control" v-model="interest" rows="8" required="true"></textarea>
										<div class="invalid-feedback" v-if="errors.interest">
											<p>{{ errors.interest[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end interest -->

								<!-- self_evaluation -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-self_evaluation">PRO-SEED: DESCREVA SUA MAIOR QUALIDADE</label>
										<textarea type="text" id="input-self_evaluation" v-bind:class="{'is-invalid': errors.self_evaluation}" class="form-control" v-model="self_evaluation" rows="8" required="true"></textarea>
										<div class="invalid-feedback" v-if="errors.self_evaluation">
											<p>{{ errors.self_evaluation[0] }}</p>
										</div>
									</div><!-- form group -->
								</div><!-- col-lg-12 -->
								<!-- end self_evaluation -->

							</div>
						</div>
						<!-- end tab 5 -->

						<!-- tab 6 -->
						<div class="tab-pane fade" id="tab6" role="tabpanel" aria-labelledby="tab-6">
							<button class="btn btn-primary mb-3" @click="addExamResult()">+ Arquivo</button>

							<div class="row">
                                <!-- table -->
                                <div class="table-responsive pb-4" v-if="!loading && exam_results.length > 0">
                                    <table class="table table-striped table-flush bg-white" id="datatable-donors">
                                        <thead class="thead-light">
                                            <tr>
                                                <th>ID</th>
                                                <th>Data</th>
                                                <th>Sessão</th>
                                                <th>Descrição</th>
                                                <th>Resultado</th>
                                                <th>Cadastrado em</th>
                                                <th>Atualizado em</th>
                                                <th class="text-right" width="200px">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="exam_result, index in exam_results" :key="index">
                                                <td>{{exam_result.id}}</td>
                                                <td>{{exam_result.document_date | moment('DD/MM/YYYY') }}</td>
                                                <td>{{exam_result.session ?? '-'}}</td>
                                                <td>{{exam_result.title ?? '-'}}</td>
                                                <td>{{exam_result.description ?? '-'}}</td>
                                                <td>{{exam_result.created_at | moment('DD/MM/YYYY HH:mm:ss')}}</td>
                                                <td>{{exam_result.updated_at | moment('DD/MM/YYYY HH:mm:ss')}}</td>
                                                <td class="text-right">
                                                    <a target="_blank" :href="exam_result.file.url" class="btn btn-sm btn-primary" title="Ver arquivo">
                                                        <i class="fas fa-eye"></i>
                                                    </a>
                                                    <button @click="editExamResult(exam_result)" class="btn btn-sm btn-warning" title="Editar">
                                                        <i class="fas fa-edit"></i>
                                                    </button>
                                                    <button @click="delExamResult(index)" class="btn btn-sm btn-danger" title="Excluir">
                                                        <i class="fas fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="!exam_results.length" class="text-center p-3 text-muted">
                                        <h5>Nenhum arquivo encontrado(a)!</h5>
                                    </div>
                                </div>
                                <!-- end table -->
							</div>
						</div>
						<!-- end tab 6 -->

					</div><!-- tab content -->
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>

        <ExamResultsModal
            v-if="new_exam_results"
            :exam_result="new_exam_results"
            @close="new_exam_results = null"
            @storeExamResult="storeExamResult"
            @onFileChange="onFileChange"
        />

        <UpdateExamResultsModal
            v-if="update_exam_results"
            :exam_result="update_exam_results"
            @close="update_exam_results = null"
            @updateExamResult="updateExamResult"
            @onFileChange="onFileChange"
        />
	</div><!-- row -->
</template>

<script>
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import ExamResultsModal from './new-examresults.vue';
    import UpdateExamResultsModal from './update-examresults.vue';

	const random = () => Math.random().toString(36).substring(7)

	export default {
		props: ["donors"],
        components: {
            ExamResultsModal,
            UpdateExamResultsModal
        },
		data() {
			return {
				internal_id: this.getData("internal_id"),
				procedures: this.getData("procedures"),
				ethnic_groups: this.getData("ethnic_groups"),
				languages: this.getData("languages"),
				age: this.getData("age"),
                surname: this.getData("surname"),
                donor_type: this.getData("donor_type"),
				height: this.getData("height"),
				weight: this.getData("weight"),
				occupation: this.getData("occupation"),
				hobby: this.getData("hobby"),
				food: this.getData("food"),
				color: this.getData("color"),
				animal: this.getData("animal"),
				trip: this.getData("trip"),
				music: this.getData("music"),
				smoking: this.getData("smoking"),
				military_service: this.getData("military_service"),
				visually_impaired: this.getData("visually_impaired"),
				hearing_impairment: this.getData("hearing_impairment"),
				allergy: this.getData("allergy"),
				hla_c: this.getData("hla_c"),
				dentist_regularly: this.getData("dentist_regularly"),
				physical_activities: this.getData("physical_activities"),
				supplemental_diet: this.getData("supplemental_diet"),
				twins_in_family: this.getData("twins_in_family"),
				team_vision: this.getData("team_vision"),
				donor_story: this.getData("donor_story"),
				active: this.getData("active"),
				cariotipo: this.getData("cariotipo"),
				sunburn: this.getData("sunburn"),
				freckles: this.getData("freckles"),
				nose: this.getData("nose"),
				other_nose: this.getData("other_nose"),
				nostrils: this.getData("nostrils"),
				lips: this.getData("lips"),
				face_shape: this.getData("face_shape"),
				particularities: this.getData("particularities"),
				school: this.getData("school"),
				school_name: this.getData("school_name"),
				personality: this.getData("personality"),
				movies: this.getData("movies"),
				eye_surgery: this.getData("eye_surgery"),
				negros_family: this.getData("negros_family"),
				negros_family_degree: this.getData("negros_family_degree"),
				father_nose: this.getData("father_nose"),
				father_nostrils: this.getData("father_nostrils"),
				father_lips: this.getData("father_lips"),
				mother_nose: this.getData("mother_nose"),
				mother_nostrils: this.getData("mother_nostrils"),
				mother_lips: this.getData("mother_lips"),
				first_degree_complete: this.getData("first_degree_complete"),
				first_degree_incomplete: this.getData("first_degree_incomplete"),
				second_degree_complete: this.getData("second_degree_complete"),
				second_degree_incomplete: this.getData("second_degree_incomplete"),
				superior_complete: this.getData("superior_complete"),
				superior_complete_name: this.getData("superior_complete_name"),
				superior_incomplete: this.getData("superior_incomplete"),
				superior_incomplete_name: this.getData("superior_incomplete_name"),
				mba: this.getData("mba"),
				mba_name: this.getData("mba_name"),
				phd: this.getData("phd"),
				phd_name: this.getData("phd_name"),
				others: this.getData("others"),
				others_name: this.getData("others_name"),
				father_father_country: this.getData("father_father_country"),
				father_mother_country: this.getData("father_mother_country"),
				mother_father_country: this.getData("mother_father_country"),
				mother_mother_country: this.getData("mother_mother_country"),
				great_grandparents: this.getData("great_grandparents"),
				great_great_grandparents: this.getData("great_great_grandparents"),
				father_country: this.getData("father_country"),
				mother_country: this.getData("mother_country"),
				father_other_nose: this.getData("father_other_nose"),
				mother_other_nose: this.getData("mother_other_nose"),
				gravidez: this.getData("gravidez"),
				anemia_falsiforme: this.getData("anemia_falsiforme"),
				talassemia: this.getData("talassemia"),
				baldness: this.getData("baldness"),
				father_baldness: this.getData("father_baldness"),
				mother_baldness: this.getData("mother_baldness"),
				interest: this.getData("interest"),
				self_evaluation: this.getData("self_evaluation"),
				blood_group_id: this.getData("blood_group_id"),
				bloodgroups: [],
				race_id: this.getData("race_id"),
				races: [],
                sperm_bank_id: this.getData("sperm_bank_id"),
                sperm_banks: [],
				religion_id: this.getData("religion_id"),
				religions: [],
				skin_color_id: this.getData("skin_color_id"),
				skincolors: [],
				hair_color_id: this.getData("hair_color_id"),
				haircolors: [],
				hair_texture_id: this.getData("hair_texture_id"),
				hairtextures: [],
				eye_color_id: this.getData("eye_color_id"),
				eyecolors: [],
				zodiac_sign_id: this.getData("zodiac_sign_id"),
				zodiacsigns: [],
				bone_structure_id: this.getData("bone_structure_id"),
				bonestructures: [],
				father_skin_color_id: this.getData("father_skin_color_id"),
				father_eye_color_id: this.getData("father_eye_color_id"),
				father_hair_color_id: this.getData("father_hair_color_id"),
				father_hair_texture_id: this.getData("father_hair_texture_id"),
				mother_skin_color_id: this.getData("mother_skin_color_id"),
				mother_eye_color_id: this.getData("mother_eye_color_id"),
				mother_hair_color_id: this.getData("mother_hair_color_id"),
				mother_hair_texture_id: this.getData("mother_hair_texture_id"),
				exam_results: [],
                new_exam_results: null,
                update_exam_results: null,
				errors: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
			this.loadBloodGroups();
			this.loadRaces();
            this.loadSpermBanks();
			this.loadReligions();
			this.loadSkinColors();
			this.loadHairColors();
			this.loadHairTextures();
			this.loadEyeColors();
			this.loadZodiacSigns();
			this.loadBoneStructures();
			this.loadExamResults();
		},
		methods: {
			addExamResult() {
				this.new_exam_results = {
					id: random(),
					title: '',
                    session: 'Documentos do Doador',
                    document_date: new Date().toISOString().split('T')[0],
					description: '',
					createdAt: null,
					file: {
						id: 0,
						name: '',
						base64: null,
						url: '',
						created_at: null,
					},
				}
			},
            editExamResult(exam_result) {
                this.update_exam_results = exam_result;
            },
			delExamResult(index) {
				this.exam_results.splice(index, 1);
			},
            storeExamResult() {
              this.exam_results.push(this.new_exam_results);
              this.new_exam_results = null;
            },
            updateExamResult() {
                this.exam_results = this.exam_results.map((exam_result) => {
                    if (exam_result.id === this.update_exam_results.id) {
                        return this.update_exam_results;
                    }
                    return exam_result;
                });
                this.update_exam_results = null;
            },
			onFileChange(e, index) {
				let files = e.target.files || e.dataTransfer.files;

				if (!files.length)
					return;

				const file = files[0]
				const fileReader = new FileReader();

				fileReader.onload = (fileLoadedEvent) => {
                    if (this.update_exam_results) {
                        this.update_exam_results.file = {
                            id: -1,
                            name: file.name,
                            base64: fileLoadedEvent.target.result,
                            url: '',
                            created_at: null,
                        }
                    } else {
                        this.new_exam_results.file = {
                            id: -1,
                            name: file.name,
                            base64: fileLoadedEvent.target.result,
                            url: '',
                            created_at: null,
                        }
                    }
				}

				fileReader.readAsDataURL(file)
			},
			removeFile(index) {
				this.exam_results[index].file = {
					id: 0,
					name: '',
					base64: null,
					url: '',
					created_at: null,
				}
			},
			getData(key) {
				return JSON.parse(this.donors)[key];
			},
			handleFileUpload(field){
				this.field = this.$refs.field.files[0];
			},
			updateData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"internal_id" : _this.internal_id,
					"procedures" : _this.procedures,
					"ethnic_groups" : _this.ethnic_groups,
					"languages" : _this.languages,
					"age" : _this.age,
                    "surname" : _this.surname,
                    "donor_type" : _this.donor_type,
					"height" : _this.height,
					"weight" : _this.weight,
					"occupation" : _this.occupation,
					"hobby" : _this.hobby,
					"food" : _this.food,
					"color" : _this.color,
					"animal" : _this.animal,
					"trip" : _this.trip,
					"music" : _this.music,
					"smoking" : _this.smoking,
					"military_service" : _this.military_service,
					"visually_impaired" : _this.visually_impaired,
					"hearing_impairment" : _this.hearing_impairment,
					"allergy" : _this.allergy,
					"hla_c" : _this.hla_c,
					"dentist_regularly" : _this.dentist_regularly,
					"physical_activities" : _this.physical_activities,
					"supplemental_diet" : _this.supplemental_diet,
					"twins_in_family" : _this.twins_in_family,
					"team_vision" : _this.team_vision,
					"donor_story" : _this.donor_story,
					"active" : _this.active,
					"cariotipo" : _this.cariotipo,
					"sunburn" : _this.sunburn,
					"freckles" : _this.freckles,
					"nose" : _this.nose,
					"other_nose" : _this.other_nose,
					"nostrils" : _this.nostrils,
					"lips" : _this.lips,
					"face_shape" : _this.face_shape,
					"particularities" : _this.particularities,
					"school" : _this.school,
					"school_name" : _this.school_name,
					"personality" : _this.personality,
					"movies" : _this.movies,
					"eye_surgery" : _this.eye_surgery,
					"negros_family" : _this.negros_family,
					"negros_family_degree" : _this.negros_family_degree,
					"father_nose" : _this.father_nose,
					"father_nostrils" : _this.father_nostrils,
					"father_lips" : _this.father_lips,
					"mother_nose" : _this.mother_nose,
					"mother_nostrils" : _this.mother_nostrils,
					"mother_lips" : _this.mother_lips,
					"first_degree_complete" : _this.first_degree_complete,
					"first_degree_incomplete" : _this.first_degree_incomplete,
					"second_degree_complete" : _this.second_degree_complete,
					"second_degree_incomplete" : _this.second_degree_incomplete,
					"superior_complete" : _this.superior_complete,
					"superior_complete_name" : _this.superior_complete_name,
					"superior_incomplete" : _this.superior_incomplete,
					"superior_incomplete_name" : _this.superior_incomplete_name,
					"mba" : _this.mba,
					"mba_name" : _this.mba_name,
					"phd" : _this.phd,
					"phd_name" : _this.phd_name,
					"others" : _this.others,
					"others_name" : _this.others_name,
					"father_father_country" : _this.father_father_country,
					"father_mother_country" : _this.father_mother_country,
					"mother_father_country" : _this.mother_father_country,
					"mother_mother_country" : _this.mother_mother_country,
					"great_grandparents" : _this.great_grandparents,
					"great_great_grandparents" : _this.great_great_grandparents,
					"father_country" : _this.father_country,
					"mother_country" : _this.mother_country,
					"father_other_nose" : _this.father_other_nose,
					"mother_other_nose" : _this.mother_other_nose,
					"gravidez" : _this.gravidez,
					"anemia_falsiforme" : _this.anemia_falsiforme,
					"talassemia" : _this.talassemia,
					"baldness" : _this.baldness,
					"father_baldness" : _this.father_baldness,
					"mother_baldness" : _this.mother_baldness,
					"interest" : _this.interest,
					"self_evaluation" : _this.self_evaluation,
					"blood_group_id" : _this.blood_group_id,
					"race_id" : _this.race_id,
                    "sperm_bank_id" : _this.sperm_bank_id,
					"religion_id" : _this.religion_id,
					"skin_color_id" : _this.skin_color_id,
					"hair_color_id" : _this.hair_color_id,
					"hair_texture_id" : _this.hair_texture_id,
					"eye_color_id" : _this.eye_color_id,
					"zodiac_sign_id" : _this.zodiac_sign_id,
					"bone_structure_id" : _this.bone_structure_id,
					"father_skin_color_id" : _this.father_skin_color_id,
					"father_eye_color_id" : _this.father_eye_color_id,
					"father_hair_color_id" : _this.father_hair_color_id,
					"father_hair_texture_id" : _this.father_hair_texture_id,
					"mother_skin_color_id" : _this.mother_skin_color_id,
					"mother_eye_color_id" : _this.mother_eye_color_id,
					"mother_hair_color_id" : _this.mother_hair_color_id,
					"mother_hair_texture_id" : _this.mother_hair_texture_id,
					"exam_results" : _this.exam_results,
					"_method": "PATCH"
				};

                console.log('_this.exam_results', _this.exam_results);

				axios.post("/admin/donors/"+this.getData('id'), dataInfo).then((res) => {

					_this.loading = false;

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "Atualizações enviadas para aprovação!",
						icon: "success"
					});
				}).catch((err) => {

					_this.loading = false;

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao atualizar doador. Tente novamente!",
						icon: "error"
					});
				});
			},
			loadExamResults() {
				const exam_results = this.getData('exam_results')
				if (exam_results != null && exam_results.length > 0) {
					this.exam_results = exam_results
				}
			},
			loadBloodGroups() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/bloodgroups").then((res) => {
					_this.bloodgroups = _this.bloodgroups.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadRaces() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/races").then((res) => {
					_this.races = _this.races.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSpermBanks() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/spermbanks").then((res) => {
					_this.sperm_banks = _this.sperm_banks.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadReligions() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/religions").then((res) => {
					_this.religions = _this.religions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSkinColors() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/skincolors").then((res) => {
					_this.skincolors = _this.skincolors.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadHairColors() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/haircolors").then((res) => {
					_this.haircolors = _this.haircolors.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadHairTextures() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/hairtextures").then((res) => {
					_this.hairtextures = _this.hairtextures.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadEyeColors() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/eyecolors").then((res) => {
					_this.eyecolors = _this.eyecolors.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadZodiacSigns() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/zodiacsigns").then((res) => {
					_this.zodiacsigns = _this.zodiacsigns.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadBoneStructures() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/bonestructures").then((res) => {
					_this.bonestructures = _this.bonestructures.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			}
		}
	}
</script>
