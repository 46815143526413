<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>

					<a @click="newData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>
					<div class="">
						<div class="row">

							<!-- name -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-name">Nome</label>
									<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}" class="form-control" placeholder="Nome" v-model="name" required="true">
									<div class="invalid-feedback" v-if="errors.name">
										<p>{{ errors.name[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-lg-6 -->
							<!-- end name -->

							<!-- internal_id -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-internal_id">ID interno</label>
									<input type="text" id="input-internal_id" v-bind:class="{'is-invalid': errors.internal_id}" class="form-control" placeholder="ID interno" v-model="internal_id" required="true">
									<div class="invalid-feedback" v-if="errors.internal_id">
										<p>{{ errors.internal_id[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-lg-6 -->
							<!-- end internal_id -->

						</div>
					</div>
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
	export default {
		data() {
			return {
				name: "",
				errors: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
		},
		methods: {
			handleFileUpload(field){
				this.field = this.$refs.field.files[0];
			},
			newData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"name" : _this.name,
					"internal_id" : _this.internal_id,
					"last": ""
				};
				axios.post("/admin/eyecolors", dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "EyeColors cadastrado com sucesso!",
						icon: "success"
					});
				}).catch((err) => {

					_this.loading = false;

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao cadastrar EyeColors. Tente novamente!",
						icon: "error"
					});
				});
			},
			resetForm() {
				this.name = '';
				this.internal_id = '';
			}
		}
	}
</script>
