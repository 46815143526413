<template>
	<div class="row">
		<div class="col">

			<!-- table -->
			<div class="table-responsive pb-4" v-if="!loading && haircolors.length > 0">
				<table class="table table-striped table-flush bg-white" id="datatable">
					<thead class="thead-light">
						<tr>
							<th>Nome</th>
							<th>Criado em</th>
							<th class="text-right">Ações</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item, index in haircolors" :key="index">
							<td>{{item.name}}</td>
							<td>{{item.created_at | moment('DD/MM/YYYY HH:mm:ss')}}</td>
							<td class="text-right">
								<div class="dropdown">
									<a class="btn btn-sm btn-icon-only" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<i class="fas fa-cogs"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow text-right" v-if="canEdit || canDelete">
										<a class="dropdown-item" @click="editData(item.id)" v-if="canEdit"><i class="far fa-edit"></i> Editar</a>
										<a class="dropdown-item text-danger" @click="deleteData(item.id, index)" v-if="canDelete"><i class="far fa-trash-alt"></i> Excluir</a>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<div v-if="!haircolors.length" class="text-center p-3 text-muted">
					<h5>Nenhuma cor de cabelo encontrada!</h5>
				</div>
			</div>
			<!-- end table -->

			<div class="text-center p-4 text-muted" v-if="loading">
				<h5>Carregando...</h5>
				<p>Aguarde até que os dados sejam carregados...</p>
			</div>

		</div><!-- col -->
	</div><!-- row -->
</template>

<script>
	export default {
		props: [
			'canEdit',
			'canDelete',
		],
		data() {
			return {
				haircolors: [],
				loading: false
			}
		},
		mounted() {
			this.loadItems();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/haircolors").then((res) => {
					_this.haircolors = _this.haircolors.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir haircolors",
					text: "Tem certeza que deseja excluir esse(a) haircolors?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove user
						axios.delete("/admin/haircolors/"+ID).then((res) => {

							_this.haircolors.splice(index, 1);
							_this.total = _this.total - 1;
							//_this.loadItems();

							//show confirm alert
							_this.$swal({
								title: "Sucesso",
								text: "HairColors excluído com sucesso!",
								icon: "success"
							});

						}).catch(error => {

							//show error alert
							_this.$swal({
								title: "Erro",
								text: "Falha ao excluir o(a) haircolors. Tente novamente!",
								icon: "error"
							});

						});

					} else {

						//only close

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(ID){
				location.href="/admin/haircolors/"+ID+"/edit";
			}
		}
	}
</script>
