<template>
	<div class="row">
        <div class="col-12">
            <!-- filters -->
            <form>
                <div class="row">
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-internal_id">ID Interno</label>
                                    <input type="text" class="form-control" name="internal_id" id="input-internal_id" placeholder="ID Interno">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-active">Situação</label>
                                    <select class="form-control" id="input-active" name="active">
                                        <option value="">Selecione</option>
                                        <option value="1">Ativo</option>
                                        <option value="0">Desativado</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-sperm_bank_id">Banco de Sêmen</label>
                                    <select type="text" id="input-sperm_bank_id" class="form-control" name="sperm_bank_id" data-toggle="select" v-model="selectedSpermBank">
                                        <option value="">Selecione</option>
                                        <option v-for="item in sperm_banks" :value="item.id" :key="item.id">{{item.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="form-control-label" for="input-search">&nbsp;</label>
                                    <button type="submit" class="btn btn-primary btn-block">Buscar</button>
                                </div>
                            </div>
                            <div class="col-md-6" v-if="canCreate">
                                <div class="form-group">
                                    <a href="/admin/donors/create">
                                        <label class="form-control-label" for="input-search">&nbsp;</label>
                                        <button type="button" class="btn btn-outline-success btn-block">Novo</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
		<div class="col">
			<!-- table -->
			<div class="table-responsive pb-4" v-if="!loading && donors.length > 0">
				<table class="table table-striped table-flush bg-white" id="datatable-donors">
					<thead class="thead-light">
						<tr>
							<th>ID Interno</th>
							<th>Banco de Sêmen</th>
							<th>Situação</th>
							<th>Sangue</th>
							<th>Raça</th>
							<th>Cor da Pele</th>
							<th>Cor dos Olhos</th>
							<th>Cadastrado em</th>
							<th>Atualizado em</th>
							<th class="text-right">Ações</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item, index in donors" :key="index">
							<td>
                                <a class="btn btn-sm btn-icon-only" href="#" role="button" @click="editData(item.id)" v-if="canEdit">
                                    <i class="fa fa-search"></i>
                                    {{item.internal_id}}
                                </a>
                            </td>
                            <td width="150px">{{item.sperm_bank?.name ?? ''}}</td>
							<td>{{item.active == 0 ? 'Desativado' : 'Ativo'}}</td>
							<td>{{item.blood_groups?.name}}</td>
							<td>{{item.races?.name}}</td>
							<td>{{item.skin_colors?.name}}</td>
							<td>{{item.eye_colors?.name}}</td>
							<td>{{item.created_at | moment('DD/MM/YYYY HH:mm:ss')}}</td>
							<td>{{item.updated_at | moment('DD/MM/YYYY HH:mm:ss')}}</td>
							<td class="text-right">
								<div class="dropdown">
                                    <a class="btn btn-sm btn-icon-only" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-cogs"></i>
                                    </a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow text-right">
										<a class="dropdown-item" :href="'donors/'+item.id+'/updates/list'" v-if="canUpdatesView"><i class="far fa-edit"></i> Aprovações</a>
										<a class="dropdown-item text-danger" @click="deleteData(item.id, index)" v-if="canDelete"><i class="far fa-trash-alt"></i> Excluir</a>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<div v-if="!donors.length" class="text-center p-3 text-muted">
					<h5>Nenhum donors encontrado(a)!</h5>
				</div>
			</div>
			<!-- end table -->

			<div class="text-center p-4 text-muted" v-if="loading">
				<h5>Carregando...</h5>
				<p>Aguarde até que os dados sejam carregados...</p>
			</div>

		</div><!-- col -->
	</div><!-- row -->
</template>

<script>
	export default {
		props: [
			'canCreate',
			'canEdit',
			'canDelete',
            'canUpdatesView',
		],
		data() {
			return {
				donors: [],
                sperm_banks: [],
				loading: false,
                selectedSpermBank: '',
			}
		},
		mounted() {
			console.log(this.canEdit)
            this.loadSpermBanks();
			this.loadItems();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
                const internalIdEl = document.getElementById('input-internal_id');
                const activeEl = document.getElementById('input-active');
                const spermBankIdEl = document.getElementById('input-sperm_bank_id');

                const urlParams = new URLSearchParams(window.location.search);
                const internal_id = urlParams.get('internal_id');
                const active = urlParams.get('active');
                const sperm_bank_id = urlParams.get('sperm_bank_id');

                if(internal_id){
                    internalIdEl.value = internal_id;
                }

                if(active){
                    activeEl.value = active;
                }

                if(sperm_bank_id){
                    this.selectedSpermBank = sperm_bank_id || '';
                }

				axios.get("/admin/donors", {
                    params: {
                        internal_id,
                        active,
                        sperm_bank_id
                    }
                }).then((res) => {
                    console.log(res.data);
					_this.donors = _this.donors.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadSpermBanks() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/spermbanks").then((res) => {
					_this.sperm_banks = _this.sperm_banks.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir donors",
					text: "Tem certeza que deseja excluir esse(a) donors?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove user
						axios.delete("/admin/donors/"+ID).then((res) => {

							console.log(res);

							_this.donors.splice(index, 1);
							_this.total = _this.total - 1;
							//_this.loadItems();

							//show confirm alert
							_this.$swal({
								title: "Sucesso",
								text: "Donors excluído com sucesso!",
								icon: "success"
							});

						}).catch(error => {

							//show error alert
							_this.$swal({
								title: "Erro",
								text: "Falha ao excluir o(a) donors. Tente novamente!",
								icon: "error"
							});

						});

					} else {

						//only close

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(ID){
				location.href="/admin/donors/"+ID+"/edit";
			}
		}
	}
</script>
