<template>
  <transition name="modal">
    <div class="modal fade show" style="display: block !important">
      <div class="modal-dialog" style="min-width: 620px;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Arquivos do Doador</h5>
            <button type="button" class="close" @click="$emit('close')">
              <span>&times;</span>
            </button>
          </div>

          <div class="modal-body pr-0 pl-0 pb-0">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$emit('close')">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {

  }
</script>
