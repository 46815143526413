<template>
	<div class="row">
		<div class="col">
			<donorsupdateexamresults-component v-if="showModal" @close="showModal = false">
				<div slot="body">
					<table v-if="exam_results.length > 0" class="table mb-0">
						<thead>
							<tr>
								<th>Título</th>
								<th>Descrição</th>
								<th>Arquivo</th>
								<th>Data</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="exam_result in exam_results" v-bind:key="exam_result.id">
								<td>{{ exam_result.title }}</td>
								<td>{{ exam_result.description }}</td>
								<td>
									<a :href="exam_result.file.url" download>{{ exam_result.file.name }}</a>
								</td>
								<td>{{ new Date(exam_result.created_at).toDateString() }}</td>
							</tr>
						</tbody>
					</table>
					<div v-else class="text-center p-2 bg-light">N/A</div>
				</div>
			</donorsupdateexamresults-component>

			<!-- table -->
			<div class="table-responsive pb-4" v-if="!loading && updates.length > 0">
				<table class="table table-striped table-flush bg-white" id="datatable" data-order-key="6" data-order-value="asc">
					<thead class="thead-light">
						<tr>
							<th>Campo</th>
							<th>Anterior</th>
							<th>Alterado para</th>
							<th>Alterado por</th>
							<th>Aprovado/Reprovado por</th>
							<th>Status</th>
							<th>Atualizado em</th>
							<th class="text-right">Ações</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item, index in updates" :key="index">
							<td>{{item.field_label}}</td>
							<td>
								<button v-if="item.field === 'exam_results' && item.old_value != '' && item.old_value != '[]'" class="btn btn-link p-0" @click="showExamResults(item.old_value, item.new_value, 'old')" :disabled="isLoading">Ver detalhes</button>
								<span v-else>{{ item.old_value == '[]' ? 'N/A' : item.old_value }}</span>
							<td>
								<button v-if="item.field === 'exam_results'" class="btn btn-link p-0" @click="showExamResults(item.old_value, item.new_value, 'new')" :disabled="isLoading">Ver detalhes</button>
								<span v-else>{{ item.new_value == '[]' ? 'N/A' : item.new_value }}</span>
							</td>
							<td>{{item.user ? item.user.email : null}}</td>
							<td>{{item.change_user ? item.change_user.email : null}}</td>
							<td>{{status(item.status)}}</td>
							<td>{{item.updated_at | moment('DD/MM/YYYY HH:mm:ss')}}</td>
							<td class="text-right">
								<div class="dropdown" v-if="item.status == 0 && canApprove">
									<a class="btn btn-sm btn-icon-only" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<i class="fas fa-cogs"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow text-right">
										<a class="dropdown-item" @click="approve(index, item.id)"><i class="far fa-edit"></i> Aprovar</a>
										<a class="dropdown-item text-danger" @click="repprove(index, item.id)"><i class="far fa-trash-alt"></i> Reprovar</a>
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else class="text-center p-3 text-muted">
				<h5>Nenhuma alteração encontrada para este Doador!</h5>
			</div>
			<!-- end table -->

			<div class="text-center p-4 text-muted" v-if="loading">
				<h5>Carregando...</h5>
				<p>Aguarde até que os dados sejam carregados...</p>
			</div>

		</div><!-- col -->
	</div><!-- row -->
</template>

<script>
	export default {
		props: [
			'id',
			'canApprove',
		],
		data() {
			return {
				updates: [],
				loading: false,
				showModal: false,
				isLoading: false,
				exam_results: [],
			}
		},
		mounted() {
			this.loadItems();
		},
		methods: {
			showExamResults(old_value, new_value, type) {
				let ids = []

				if (type == 'old') {
					ids = JSON.parse(old_value)
				} else {
					console.log(new_value)

					const old_ids = JSON.parse(old_value)
					const new_ids = JSON.parse(new_value)

					ids = new_ids.created

					for (let id of old_ids) {
						if (!new_ids.updated.includes(id) && !new_ids.deleted.includes(id)) {
							ids.push(id)
						}
					}

					if (ids.length === 0) {
						this.exam_results = []
						this.showModal = true
						this.isLoading = false
					}
				}

				if (ids.length > 0) {
					this.isLoading = true

					axios.get(`/admin/donors/${this.id}/exam-results`, {
						params: {
							ids: ids.join(','),
						},
					}).then(({ data }) => {
						this.exam_results = data
						this.showModal = true
					}).finally(() => {
						this.isLoading = false
					})
				}
			},
			loadItems() {
				let _this = this;
				axios.get("/admin/donors/"+this.id+"/updates/list").then((res) => {
					_this.updates = _this.updates.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
      		},
			approve(i, log_donor_id) {
				axios.put("/admin/donors/"+this.id+"/updates/approve", {
					log_donor_id,
				}).then(({ data }) => {
					this.updates[i].change_user = data.change_user;
					this.updates[i].status = 1;
				});
			},
			repprove(i, log_donor_id) {
				axios.put("/admin/donors/"+this.id+"/updates/repprove", {
					log_donor_id,
				}).then(({ data }) => {
					this.updates[i].change_user = data.change_user;
					this.updates[i].status = 2;
				});
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir donors",
					text: "Tem certeza que deseja excluir esse(a) donors?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove user
						axios.delete("/admin/donors/"+ID).then((res) => {

							console.log(res);

							_this.donors.splice(index, 1);
							_this.total = _this.total - 1;
							//_this.loadItems();

							//show confirm alert
							_this.$swal({
								title: "Sucesso",
								text: "Donors excluído com sucesso!",
								icon: "success"
							});

						}).catch(error => {

							//show error alert
							_this.$swal({
								title: "Erro",
								text: "Falha ao excluir o(a) donors. Tente novamente!",
								icon: "error"
							});

						});

					} else {

						//only close

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			status(id) {
				if (id == 2) {
					return 'Recusado';
				} else if (id == 1) {
					return 'Aprovado';
				}

				return 'Aguardando Aprovação';
			}
		}
	}
</script>
