<template>
  <div class="row">
    <div class="col">

      <!-- table -->
      <div class="table-responsive pb-4">
        <table class="table table-striped table-flush bg-white" id="datatable">
          <thead class="thead-light">
            <tr>
              <th>ID</th>
              <th>Notificação</th>
              <th>Data e Hora</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="notification in notifications" v-bind:key="notification.id" data-order="desc">
              <td>{{ notification.id }}</td>
              <td>{{ notification.action }}</td>
              <td>{{ notification.created_at | moment('DD/MM/YYYY HH:mm:ss') }}</td>
              <td>
                <button class="btn btn-primary btn-sm float-right" @click="open(notification)">Acessar</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="!notifications.length" class="text-center p-3 text-muted">
          <h5>Nenhum log encontrado!</h5>
        </div>
      </div>
      <!-- end table -->

      <div class="text-center p-4 text-muted" v-if="loading">
        <h5>Carregando...</h5>
        <p>Aguarde até que os dados sejam carregados...</p>
      </div>

    </div><!-- col -->
  </div><!-- row -->
</template>

<script>
  export default {
    data() {
      return {
        notifications: [],
        loading: false
      }
    },
    mounted() {
      this.loadNotifications();
    },
    methods: {
      loadNotifications() {
        let _this = this;
        _this.loading = true;
        axios.get('/admin/log/notifications/json', {
          params: {
            viewed: 0,
          },
        }).then((res) => {
          _this.notifications = _this.notifications.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });
      },

      open(notification) {
        axios.put(`/admin/log/notifications/${notification.id}/viewed`).finally(function() {
          if (notification.action_type === 'DONOR - PENDING') {
            window.location.href = `/admin/donors/${notification.action_id}/updates/list`;
          }
        });
      },
    }
  }
</script>
