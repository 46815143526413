<template>
    <transition name="modal">
        <div class="modal fade show" style="display: block !important">
            <div class="modal-dialog" style="min-width: 620px">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Arquivos do Doador</h5>
                        <button
                            type="button"
                            class="close"
                            @click="$emit('close')"
                        >
                            <span>&times;</span>
                        </button>
                    </div>

                    <div class="modal-body pb-0">
                        <div class="form-group mb-3">
                            <label class="form-control-label mb-0" for="input-exam_result-document_date">Data</label>
                            <input type="date" id="input-exam_result-document_date" class="form-control" v-model="exam_result.document_date">
                        </div><!-- form group -->

                        <div class="form-group mb-3">
                            <label class="form-control-label mb-0" for="input-exam_result-session">Sessão</label>
                            <select id="input-exam_result-session" class="form-control" v-model="exam_result.session">
                                <option value="Resultado de Exames">Resultado de Exames</option>
                                <option value="Documentos do Doador">Documentos do Doador</option>
                            </select>
                        </div><!-- form group -->

                        <div class="form-group mb-3">
                            <label class="form-control-label mb-0" for="input-exam_result-title">Descrição</label>
                            <input type="text" id="input-exam_result-title" class="form-control" v-model="exam_result.title">
                        </div><!-- form group -->

                        <div class="form-group mb-3">
                            <label class="form-control-label mb-0" for="input-exam_result-description">Resultado</label>
                            <textarea id="input-exam_result-description" class="form-control" v-model="exam_result.description" style="max-height: 70px;"></textarea>
                        </div><!-- form group -->

                        <div class="file-upload">
                            <label class="form-label mb-0">Arquivo</label>
                            <input class="form-control" type="file" @change="$emit('onFileChange', $event, exam_result.id)">
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="$emit('close')"
                        >
                            Fechar
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="$emit('storeExamResult')"
                        >
                            Salvar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: ["exam_result"],
    methods: {
        // ...
    },
};
</script>
