<template>
  <section class="section">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-danger">
            <i class="far fa-calendar-alt"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Agendamentos</h4>
            </div>
            <div class="card-body">
              {{schedules}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-success">
            <i class="far fa-user"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Doadores</h4>
            </div>
            <div class="card-body">
              {{donors}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-light">
            <i class="far fa-file"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Newsletters</h4>
            </div>
            <div class="card-body">
              {{newsletters}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-light">
            <i class="far fa-file"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Contatos</h4>
            </div>
            <div class="card-body">
              {{contacts}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    data() {
			return {
        contacts: 0,
        donors: 0,
        newsletters: 0,
        schedules: 0
      }
    },
    mounted() {
      this.loadInfos();
    },
    methods: {
      loadInfos(){
        let _this = this;
				axios.get("/admin/dashboard").then((res) => {
          _this.contacts = res.data.contacts;
          _this.donors = res.data.donors;
          _this.newsletters = res.data.newsletters;
          _this.schedules = res.data.schedules;
				}).catch((err) => {
					
				});
      }
    }
  };
</script>