require('./bootstrap');

window.Vue = require('vue');

//ALERT
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

//CKeditor
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use( CKEditor );

//LOADING
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.component('vue-loading', Loading);

//MOMENT
Vue.use(require('vue-moment'));

//COMPONENTS
//dashboard
import adminWidgetsComponent from './components/admin/dashboard/widgets';
Vue.component('adminwidgets-component', adminWidgetsComponent);

//logs
import LogAccessComponent from './components/admin/logs/access';
import LogActivityComponent from './components/admin/logs/activity';
import LogErrorComponent from './components/admin/logs/error';
import LogNotificationsTable from './components/admin/logs/notifications';
import LogNotificationComponent from './components/admin/dashboard/notifications';
Vue.component('lognotificationstable-component',LogNotificationsTable);
Vue.component('logaccess-component',LogAccessComponent);
Vue.component('logactivity-component',LogActivityComponent);
Vue.component('logerror-component',LogErrorComponent);
Vue.component('lognotification-component',LogNotificationComponent);

//users
import userListComponent from './components/admin/users/list';
import userAddComponent from './components/admin/users/add';
import userEditComponent from './components/admin/users/edit';
import userProfileComponent from './components/admin/users/profile'
Vue.component('userlist-component', userListComponent);
Vue.component('useradd-component', userAddComponent);
Vue.component('useredit-component', userEditComponent);
Vue.component('userprofile-component', userProfileComponent);

//permissions
import permissionsListComponent from './components/admin/users/permissions/list';
import permissionsAddComponent from './components/admin/users/permissions/add';
import permissionsEditComponent from './components/admin/users/permissions/edit';
Vue.component('permissionslist-component', permissionsListComponent);
Vue.component('permissionsadd-component', permissionsAddComponent);
Vue.component('permissionsedit-component', permissionsEditComponent);

//bonestructures
import bonestructuresListComponent from './components/admin/bonestructures/list';
import bonestructuresAddComponent from './components/admin/bonestructures/add';
import bonestructuresEditComponent from './components/admin/bonestructures/edit';
Vue.component('bonestructureslist-component', bonestructuresListComponent);
Vue.component('bonestructuresadd-component', bonestructuresAddComponent);
Vue.component('bonestructuresedit-component', bonestructuresEditComponent);

//donors
import donorsListComponent from './components/admin/donors/list';
import donorsAddComponent from './components/admin/donors/add';
import donorsEditComponent from './components/admin/donors/edit';
import donorsUpdateComponent from './components/admin/donors/updates';
import donorsUpdateExamResultsComponent from './components/admin/donors/examresults';
Vue.component('donorslist-component', donorsListComponent);
Vue.component('donorsadd-component', donorsAddComponent);
Vue.component('donorsedit-component', donorsEditComponent);
Vue.component('donorsupdateexamresults-component', donorsUpdateExamResultsComponent);
Vue.component('donorsupdate-component', donorsUpdateComponent);

//skincolors
import skincolorsListComponent from './components/admin/skincolors/list';
import skincolorsAddComponent from './components/admin/skincolors/add';
import skincolorsEditComponent from './components/admin/skincolors/edit';
Vue.component('skincolorslist-component', skincolorsListComponent);
Vue.component('skincolorsadd-component', skincolorsAddComponent);
Vue.component('skincolorsedit-component', skincolorsEditComponent);

//zodiacsigns
import zodiacsignsListComponent from './components/admin/zodiacsigns/list';
import zodiacsignsAddComponent from './components/admin/zodiacsigns/add';
import zodiacsignsEditComponent from './components/admin/zodiacsigns/edit';
Vue.component('zodiacsignslist-component', zodiacsignsListComponent);
Vue.component('zodiacsignsadd-component', zodiacsignsAddComponent);
Vue.component('zodiacsignsedit-component', zodiacsignsEditComponent);

//religions
import religionsListComponent from './components/admin/religions/list';
import religionsAddComponent from './components/admin/religions/add';
import religionsEditComponent from './components/admin/religions/edit';
Vue.component('religionslist-component', religionsListComponent);
Vue.component('religionsadd-component', religionsAddComponent);
Vue.component('religionsedit-component', religionsEditComponent);

//races
import racesListComponent from './components/admin/races/list';
import racesAddComponent from './components/admin/races/add';
import racesEditComponent from './components/admin/races/edit';
Vue.component('raceslist-component', racesListComponent);
Vue.component('racesadd-component', racesAddComponent);
Vue.component('racesedit-component', racesEditComponent);

//procedures
import proceduresListComponent from './components/admin/procedures/list';
import proceduresAddComponent from './components/admin/procedures/add';
import proceduresEditComponent from './components/admin/procedures/edit';
Vue.component('procedureslist-component', proceduresListComponent);
Vue.component('proceduresadd-component', proceduresAddComponent);
Vue.component('proceduresedit-component', proceduresEditComponent);

//languages
import languagesListComponent from './components/admin/languages/list';
import languagesAddComponent from './components/admin/languages/add';
import languagesEditComponent from './components/admin/languages/edit';
Vue.component('languageslist-component', languagesListComponent);
Vue.component('languagesadd-component', languagesAddComponent);
Vue.component('languagesedit-component', languagesEditComponent);

//hairtextures
import hairtexturesListComponent from './components/admin/hairtextures/list';
import hairtexturesAddComponent from './components/admin/hairtextures/add';
import hairtexturesEditComponent from './components/admin/hairtextures/edit';
Vue.component('hairtextureslist-component', hairtexturesListComponent);
Vue.component('hairtexturesadd-component', hairtexturesAddComponent);
Vue.component('hairtexturesedit-component', hairtexturesEditComponent);

//haircolors
import haircolorsListComponent from './components/admin/haircolors/list';
import haircolorsAddComponent from './components/admin/haircolors/add';
import haircolorsEditComponent from './components/admin/haircolors/edit';
Vue.component('haircolorslist-component', haircolorsListComponent);
Vue.component('haircolorsadd-component', haircolorsAddComponent);
Vue.component('haircolorsedit-component', haircolorsEditComponent);

//eyecolors
import eyecolorsListComponent from './components/admin/eyecolors/list';
import eyecolorsAddComponent from './components/admin/eyecolors/add';
import eyecolorsEditComponent from './components/admin/eyecolors/edit';
Vue.component('eyecolorslist-component', eyecolorsListComponent);
Vue.component('eyecolorsadd-component', eyecolorsAddComponent);
Vue.component('eyecolorsedit-component', eyecolorsEditComponent);

//ethnicgroups
import ethnicgroupsListComponent from './components/admin/ethnicgroups/list';
import ethnicgroupsAddComponent from './components/admin/ethnicgroups/add';
import ethnicgroupsEditComponent from './components/admin/ethnicgroups/edit';
Vue.component('ethnicgroupslist-component', ethnicgroupsListComponent);
Vue.component('ethnicgroupsadd-component', ethnicgroupsAddComponent);
Vue.component('ethnicgroupsedit-component', ethnicgroupsEditComponent);

//bloodgroups
import bloodgroupsListComponent from './components/admin/bloodgroups/list';
import bloodgroupsAddComponent from './components/admin/bloodgroups/add';
import bloodgroupsEditComponent from './components/admin/bloodgroups/edit';
Vue.component('bloodgroupslist-component', bloodgroupsListComponent);
Vue.component('bloodgroupsadd-component', bloodgroupsAddComponent);
Vue.component('bloodgroupsedit-component', bloodgroupsEditComponent);

//RENDER
const app = new Vue({
	el: '#app',
	methods: {
		MakeUrl(path) {
			return BaseUrl(path);
		}
	}
});
