<template>
  <div class="row">
    <div class="col">

      <!-- table -->
      <div class="table-responsive pb-4">
        <table class="table table-striped table-flush bg-white" id="datatable" data-order-key="5" data-order-value="desc">
          <thead class="thead-light">
            <tr>
              <th>#</th>
              <th>Usuário</th>
              <th>Ação</th>
              <th>Tipo</th>
              <th>Recurso</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            <tr class="title">
              <td>#</td>
              <td>Usuário</td>
              <td>Ação</td>
              <td>Tipo</td>
              <td>Recurso</td>
              <td>Data</td>
            </tr>
            <tr v-for="log, index in logs" :key="index">
              <td>{{ log.id }}</td>
              <td>{{ log.user ? log.user.email : null }}</td>
              <td>{{ log.action }}</td>
              <td>{{ log.action_type }}</td>
              <td>{{ log.resource }}</td>
              <td>{{ log.created_at | moment('YYYY/MM/DD HH:mm:ss') }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="!logs.length" class="text-center p-3 text-muted">
            <h5>Nenhum log encontrado!</h5>
        </div>
      </div>
      <!-- end table -->

      <div class="text-center p-4 text-muted" v-if="loading">
        <h5>Carregando...</h5>
        <p>Aguarde até que os dados sejam carregados...</p>
      </div>

    </div><!-- col -->
  </div><!-- row -->
</template>

<script>
  export default {
    data() {
      return {
        logs: [],
        loading: false,
      }
    },
    mounted() {
      this.loadLogs();
    },
    methods: {
      loadLogs() {
        let _this = this;
        _this.loading = true;
        axios.get('/admin/log/activity').then((res) => {
          _this.logs = _this.logs.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });
      }
    }
  }
</script>
